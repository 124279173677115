import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import { DataGrid } from '@mui/x-data-grid';
import Skeleton from '@mui/material/Skeleton';
import { useState, useEffect } from "react";
import { useIntl, FormattedMessage } from 'react-intl';
import { useSelector, useDispatch } from "react-redux";
import { requestedAirportsList, selectAllAirports, selectAirportsStatus } from '../_redux/airportsSlice';
import CreateEditAirportModal from '../_subs/CreateEditAirportModal';
import DeleteAirportModal from '../_subs/DeleteAirportModal';

function Airports(props) {
  const intl = useIntl()
  const dispatch = useDispatch();
  const allAirports = useSelector((state) => selectAllAirports(state));
  const airportStatus = useSelector((state) => selectAirportsStatus(state));
  const [loading, setLoading] = useState(true);
  const [selectedRows, setSelectedRows] = useState([]);
  const [showNew, setShowNew] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [showDelete, setShowDelete] = useState(false);

  useEffect(() => {
    refreshTable();
  }, [props]);

  const refreshTable = () => {
    setSelectedRows([]);
    dispatch(requestedAirportsList());
    setLoading(false);
  }

  const columns = [
    { field: 'code', headerName: intl.formatMessage({ id: 'Labels.code' }), width: 100 },
    { field: 'name', headerName: intl.formatMessage({ id: 'Labels.name' }), width: 400 },
    { field: 'city', headerName: intl.formatMessage({ id: 'Labels.city' }), width: 250 },
    { field: 'country', headerName: intl.formatMessage({ id: 'Labels.country' }), width: 250 },
  ];

  const handleShowEdit = () => setShowEdit(true);
  const handleShowNew = () => setShowNew(true);
  const handleCloseModals = () => {
    setShowNew(false);
    setShowEdit(false);
    setShowDelete(false);
  };

  const handleCloseNewRefresh = () => {
    handleCloseModals();
    refreshTable();
  };

  const handleShowDelete = () => setShowDelete(true);
  const handleCloseDelete = () => {
    handleCloseModals();
    refreshTable();
  };

  return (
    <>
      <Container>
        <div style={{ marginBottom: 5, width: '100%' }} className="d-flex justify-content-end">
          {selectedRows.length > 0 && (
            <Button variant="outline-danger" onClick={handleShowDelete} className="me-1">
              <FormattedMessage
                id="Actions.delete"
              />
            </Button>
          )}
          {selectedRows.length === 1 && (
            <Button variant="outline-primary" onClick={handleShowEdit} className="me-1">
              <FormattedMessage
                id="Actions.edit"
              />
            </Button>
          )}
          <Button variant="primary" onClick={handleShowNew}>
            <FormattedMessage
              id="Actions.new"
            />
          </Button>
        </div>
        <div style={{ height: 700, width: '100%' }}>
          {(loading || airportStatus === 'loading') ? (
            <>
              <Skeleton height={60} />
              <Skeleton animation="wave" height={60} />
              <Skeleton animation={false} height={60} />
            </>
          ) : (
            <DataGrid
              rows={allAirports}
              columns={columns}
              //pageSize={50}
              rowsPerPageOptions={[5, 10, 50, 100, 500, 1000]}
              checkboxSelection
              onSelectionModelChange={(ids) => {
                const selectedIDs = new Set(ids);
                const selectedRows = allAirports.filter((row) =>
                  selectedIDs.has(row.id),
                );

                setSelectedRows(selectedRows);
              }}
            />
          )}
        </div >
      </Container>

      {(showNew || showEdit) &&
        <CreateEditAirportModal
          closeModal={handleCloseModals}
          closeModalRefresh={handleCloseNewRefresh}
          isOpen={showNew || showEdit}
          edit={showEdit}
          item={showEdit ? selectedRows[0] : undefined}
        />
      }

      {showDelete &&
        <DeleteAirportModal
          closeModal={handleCloseModals}
          closeModalrefresh={handleCloseDelete}
          isOpen={showDelete}
          ids={selectedRows.map((item) => item.code)}
        />
      }

    </>
  )
}

export default Airports;