import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import Modal from 'react-bootstrap/Modal';
import { useState, useEffect } from "react";
import { FormattedMessage } from 'react-intl';
import { useSelector, useDispatch } from "react-redux";
import { requestedCancelBookingById, selectBookingsStatus } from '../_redux/bookingsSlice';

function CancelBookingModal(props) {
    const { isOpen, closeModal, closeModalrefresh, id } = props;
    const dispatch = useDispatch();

    const bookingStatus = useSelector((state) => selectBookingsStatus(state));

    useEffect(() => {
        if (bookingStatus === 'cancelled') {
            closeModalrefresh();
        }
    }, [bookingStatus]);

    const handleSubmit = () => {
        dispatch(requestedCancelBookingById(id));
    };

    return (
        <Modal show={isOpen} onHide={closeModal}>
            <Modal.Header closeButton>
                <Modal.Title>
                    <FormattedMessage id="Travel.cancel_booking" />
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <FormattedMessage id="Travel.cancel_booking_message" />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={closeModal}>
                    <FormattedMessage id="Actions.close" />
                </Button>
                <Button variant="danger" onClick={handleSubmit}>
                    {bookingStatus === 'loading' && (
                        <Spinner
                            as="span"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            animation="border"
                        />
                    )}
                    <FormattedMessage id="Actions.cancel" />
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default CancelBookingModal;