import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import Modal from 'react-bootstrap/Modal';
import { useState } from "react";
import { useIntl, FormattedMessage } from 'react-intl';
import AWS from 'aws-sdk';

const userPoolId = 'us-east-1_bwjXklaEw';
AWS.config.region = 'us-east-1';
const cognito = new AWS.CognitoIdentityServiceProvider();

function DeleteUserModal(props) {
    const { isOpen, closeModal, closeModalRefresh, item } = props;
    const intl = useIntl();

    const [loading, setLoading] = useState(false);

    const handleSubmit = () => {
        setLoading(true);
        var params = {
            UserPoolId: userPoolId, /* required */
            Username: item.email /* required */
        };
        cognito.adminDeleteUser(params, function (err, data) {
            setLoading(false);
            if (err) {
                console.log(err, err.stack); // an error occurred
            }
            else {
                console.log(data);           // successful response
                closeModalRefresh();
            }
        }
        );
    }

    return (
        <Modal show={isOpen} onHide={closeModal}>
            <Modal.Header closeButton>
                <Modal.Title>
                    <FormattedMessage id="Actions.delete" />
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <FormattedMessage id="Label.delete_user_message" values={{ name: item.name }} />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={closeModal}>
                    <FormattedMessage id="Actions.close" />
                </Button>
                <Button variant="danger" disabled={loading} onClick={handleSubmit}>
                    {loading && (
                        <Spinner
                            as="span"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            animation="border"
                        />
                    )}
                    <FormattedMessage id="Actions.delete" />
                </Button>
            </Modal.Footer>
        </Modal >
    );
}

export default DeleteUserModal;