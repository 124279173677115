import {
    createSlice,
    createAsyncThunk,
    createEntityAdapter
} from '@reduxjs/toolkit';
import axios from "axios";

const AIRPORTS_URL = "travel/airports";

const airportsAdapter = createEntityAdapter();
const initialState = airportsAdapter.getInitialState({
    status: 'idle',
    current: undefined,
    errors: undefined
});


// Thunk functions
export const requestedAirportsList = createAsyncThunk('airports/requestedAirportsList', async (noparams, { getState, rejectWithValue }) => {
    const response = await axios.get(AIRPORTS_URL, {
        headers: {
            'authorization-token': getState().account.current.session.signInUserSession.idToken.jwtToken
        }
    });

    if (response.data.success) {
        return response.data.content.map((item) => {return {...item, id:item.code};});
    } else {
        throw rejectWithValue(response.data);
    }
});

export const requestedAirportDetailsById = createAsyncThunk('airports/requestedAirportDetailsById', async (code, { getState, rejectWithValue }) => {
    const response = await axios.get(`${AIRPORTS_URL}/${code}`, {
        headers: {
            'authorization-token': getState().account.current.session.signInUserSession.idToken.jwtToken
        }
    });

    if (response.data.success) {
        return response.data.content;
    } else {
        throw rejectWithValue(response.data);
    }
});

export const requestedCreateAirport = createAsyncThunk('airports/requestedCreateAirport', async (airport, { getState, rejectWithValue }) => {
    const response = await axios.post(`${AIRPORTS_URL}/`, airport, {
        headers: {
            'authorization-token': getState().account.current.session.signInUserSession.idToken.jwtToken
        }
    });

    if (response.data.success) {
        return response.data.content;
    } else {
        throw rejectWithValue(response.data);
    }
});

export const requestedUpdateAirport = createAsyncThunk('airports/requestedUpdateAirport', async (airport, { getState, rejectWithValue }) => {
    delete airport.id;
    const response = await axios.put(`${AIRPORTS_URL}/${airport.code}`, airport, {
        headers: {
            'authorization-token': getState().account.current.session.signInUserSession.idToken.jwtToken
        }
    });

    if (response.data.success) {
        return response.data.content;
    } else {
        throw rejectWithValue(response.data);
    }
});

export const requestedDeleteAirports = createAsyncThunk('airports/requestedDeleteAirports', async (ids, { getState, rejectWithValue }) => {
    const response = await axios.delete(`${AIRPORTS_URL}/`, {
        data: {ids},
        headers: {
            'authorization-token': getState().account.current.session.signInUserSession.idToken.jwtToken
        }
    });

    if (response.data.success) {
        return response.data.content;
    } else {
        throw rejectWithValue(response.data);
    }
});

const airportsSlice = createSlice({
    name: 'airports',
    initialState,
    reducers: {
    },
    extraReducers: builder => {
        builder
            .addCase(requestedAirportsList.pending, (state, action) => {
                state.status = 'loading';
                state.ids = [];
                state.entities = {};
                state.current = undefined;
                state.errors = undefined;
            })
            .addCase(requestedAirportsList.fulfilled, (state, action) => {
                state.status = 'loaded';
                airportsAdapter.addMany(state, action);
            })
            .addCase(requestedAirportsList.rejected, (state, action) => {
                state.status = 'error';
                state.errors = action.payload?.errors;
            })

            .addCase(requestedAirportDetailsById.pending, (state, action) => {
                state.status = 'loading';
                state.current = undefined;
                state.errors = undefined;
            })
            .addCase(requestedAirportDetailsById.fulfilled, (state, action) => {
                state.status = 'loaded';
                state.current = action.payload;
            })
            .addCase(requestedAirportDetailsById.rejected, (state, action) => {
                state.status = 'error';
                state.errors = action.payload?.errors;
            })

            .addCase(requestedCreateAirport.pending, (state, action) => {
                state.status = 'loading';
                state.errors = undefined;
            })
            .addCase(requestedCreateAirport.fulfilled, (state, action) => {
                state.status = 'submitted';
                state.current = action.payload;
            })
            .addCase(requestedCreateAirport.rejected, (state, action) => {
                state.status = 'error';
                state.errors = action.payload?.errors;
            })

            .addCase(requestedUpdateAirport.pending, (state, action) => {
                state.status = 'loading';
                state.errors = undefined;
            })
            .addCase(requestedUpdateAirport.fulfilled, (state, action) => {
                state.status = 'submitted';
                state.current = action.payload;
            })
            .addCase(requestedUpdateAirport.rejected, (state, action) => {
                state.status = 'error';
                state.errors = action.payload?.errors;
            })

            .addCase(requestedDeleteAirports.pending, (state, action) => {
                state.status = 'loading';
                state.errors = undefined;
            })
            .addCase(requestedDeleteAirports.fulfilled, (state, action) => {
                state.status = 'deleted';
                state.current = action.payload;
            })
            .addCase(requestedDeleteAirports.rejected, (state, action) => {
                state.status = 'error';
                state.errors = action.payload?.errors;
            })
    }
});

// Selectors
export const { selectAll: selectAllAirports, selectById: selectAirportById, selectIds: selectAirportsIds, selectTotal: selectTotalAirports } =
airportsAdapter.getSelectors(state => state.airports);

export const selectAirportsStatus = (state) => state.airports.status;
export const selectAirportsCurrent = (state) => state.airports.current;
export const selectAirportsErrors = (state) => state.airports.errors;

export default airportsSlice.reducer