import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import Modal from 'react-bootstrap/Modal';
import { useState, useEffect } from "react";
import { useIntl, FormattedMessage } from 'react-intl';
import { useSelector, useDispatch } from "react-redux";
import { requestedConfirmPayment, selectAccountStatus } from '../_redux/accountSlice';

function ConfirmPaymentModal(props) {
    const { isOpen, closeModal, closeModalRefresh, payment } = props;
    const dispatch = useDispatch();
    const intl = useIntl();

    const accountStatus = useSelector((state) => selectAccountStatus(state));
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (accountStatus === 'submitted') {
            setTimeout(closeModalRefresh, 2000);
            setLoading(false);
        }
    }, [accountStatus]);

    const handleSubmit = () => {
        setLoading(true);
        dispatch(requestedConfirmPayment(payment));
    };

    return (
        <Modal show={isOpen} onHide={closeModal}>
            <Modal.Header closeButton>
                <Modal.Title>
                    <FormattedMessage id="Actions.confirm" />
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <FormattedMessage id="Labels.confirm_payment" values={{ amount: intl.formatNumber(payment.amount, { style: "currency", currency: "USD" }), userFullName: payment.userFullName }} />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={closeModal}>
                    <FormattedMessage id="Actions.close" />
                </Button>
                <Button disabled={(accountStatus === 'loading' || loading)} variant="success" onClick={handleSubmit}>
                    {(accountStatus === 'loading' || loading) ? (
                        <Spinner
                            as="span"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            animation="border"
                        />
                    ) : <></>}
                    <FormattedMessage id="Actions.confirm" />
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default ConfirmPaymentModal;