import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import Modal from 'react-bootstrap/Modal';
import { useState, useEffect } from "react";
import { FormattedMessage } from 'react-intl';
import { useSelector, useDispatch } from "react-redux";
import { requestedDeleteAirports, selectAirportsStatus } from '../_redux/airportsSlice';

function DeleteAirportModal(props) {
    const { isOpen, closeModal, closeModalrefresh, ids } = props;
    const dispatch = useDispatch();

    const airportsStatus = useSelector((state) => selectAirportsStatus(state));

    useEffect(() => {
        if (airportsStatus === 'deleted') {
            closeModalrefresh();
        }
    }, [airportsStatus]);

    const handleSubmit = () => {
        dispatch(requestedDeleteAirports(ids));
    };

    return (
        <Modal show={isOpen} onHide={closeModal}>
            <Modal.Header closeButton>
                <Modal.Title>
                    <FormattedMessage id="Travel.delete_airports" />
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <FormattedMessage id="Travel.delete_airports_message" values={{ count: ids.length }} />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={closeModal}>
                    <FormattedMessage id="Actions.close" />
                </Button>
                <Button variant="danger" onClick={handleSubmit}>
                    {airportsStatus === 'loading' && (
                        <Spinner
                            as="span"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            animation="border"
                        />
                    )}
                    <FormattedMessage id="Actions.delete" />
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default DeleteAirportModal;