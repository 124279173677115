import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import { useState, useEffect } from "react";
import { FormattedMessage, FormattedDate, FormattedTime, FormattedNumber } from 'react-intl';
import { useSelector, useDispatch } from "react-redux";
import { CSVLink, CSVDownload } from "react-csv";
import AWS from 'aws-sdk';
import { selectBookingsByRouteId } from '../_redux/bookingsSlice';

import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ControlPointDuplicateIcon from '@mui/icons-material/ControlPointDuplicate';
import SettingsIcon from '@mui/icons-material/Settings';
import EditIcon from '@mui/icons-material/Edit';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import FlightLandIcon from '@mui/icons-material/FlightLand';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteIcon from '@mui/icons-material/Delete';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import ListIcon from '@mui/icons-material/List';
import StarIcon from '@mui/icons-material/Star';
import GridOnIcon from '@mui/icons-material/GridOn';
import BrowserUpdatedIcon from '@mui/icons-material/BrowserUpdated';
import { useNavigate } from 'react-router-dom';

const S3_BUCKET = 'frsolutions-travel-store';
const REGION = 'us-east-1';


AWS.config.update({
    accessKeyId: 'AKIA3WOC4JYP2IWSWBN2',
    secretAccessKey: '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'
})

const myBucket = new AWS.S3({
    params: { Bucket: S3_BUCKET },
    region: REGION,
})

const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));

const headers = [
    { label: "Booking #", key: "bookingNumber" },
    { label: "Nombre", key: "name" },
    { label: "Masculino", key: "male" },
    { label: "Femenino", key: "female" },
    { label: "Fecha de Nacimiento", key: "dateBirth" },
    { label: "Pasaporte", key: "passport" },
];

function RouteItemCard(props) {
    const navigate = useNavigate();
    const { route, deleteRoute, editRoute, duplicateRoute, booking, addBooking } = props;

    const allBookingsByRoute = useSelector((state) => selectBookingsByRouteId(state, route.routeId));

    const [expanded, setExpanded] = useState(false);

    const [itinerarySummary, setItinerarySummary] = useState('');

    useEffect(() => {
        let itinerary = '';
        if (route.itinerary) {
            for (const item of route.itinerary) {
                itinerary += item.departureAirport + " | ";
            }
            itinerary += route.itinerary[route.itinerary.length - 1].arrivalAirport;
        }
        setItinerarySummary(itinerary);
    }, [props]);

    const getFileS3Url = (fileName) => {
        const params = {
            Bucket: S3_BUCKET,
            Key: fileName
        };

        return myBucket.getSignedUrl('getObject', params);
    }

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    return (
        <>
            <Card className='my-2'>
                <CardContent style={{ background: 'aliceblue' }}>
                    <div className='row'>
                        <div className='col-lg-1 mb-2 d-flex align-items-center'>
                            <img style={{ height: 'auto', maxWidth: '100%' }} src={getFileS3Url(route.itinerary[0].airlineLogo)} />
                        </div>
                        <div className='col-lg-4 d-flex flex-column'>
                            <span className="fw-bolder" style={{ color: '#005198' }}>{route.name}</span>
                            <span className="text-secondary fw-bold fst-italic">{route.itinerary[0].airlineName}</span>
                        </div>
                        <div className='col-lg-3 d-flex flex-column'>
                            <span className="fw-bolder" style={{ color: '#005198' }}>{itinerarySummary}</span>
                            <span className="text-secondary fw-bold fst-italic">
                                <FormattedDate month='long' day='numeric' timeZone={"UTC"} value={new Date(route.itinerary[0].departureAt * 1000)} />, <FormattedTime hour12={true} timeZone={"UTC"} value={new Date(route.itinerary[0].departureAt * 1000)} />
                            </span>
                        </div>
                        <div className='col-lg-2 d-flex flex-column'>
                            <span className={`fw-bolder ${route.status === 'OPEN' ? 'text-success' : 'text-danger'}`} style={{ color: '#005198' }}>{route.status === 'OPEN' ? <FormattedMessage id="Travel.onsale" /> : <FormattedMessage id="Travel.soldout" />}</span>
                            <span className="text-secondary fw-bold fst-italic"><FormattedMessage id="Travel.seats" values={{ count: route.businessClass + route.firstClass + route.premiumClass + route.economyClass }} /></span>
                        </div>
                        <div className='col-lg-2 d-flex d-flex flex-row-reverse'>
                            {route.autoconfirm && (<StarIcon className='' style={{ color: '#d1b121' }} />)}
                            <span className="fw-bolder" style={{ color: '#d1b121', textShadow: '.5px 1px #7a7972' }}><FormattedNumber value={route.price} style="currency" currency="USD" /></span>
                        </div>
                    </div>
                </CardContent>
                <CardActions disableSpacing style={{ background: 'white' }}>
                    {booking ? (
                        <>
                        </>
                    ) : (
                        <>
                            <IconButton className='text-primary' title='Edit' aria-label="Edit">
                                <SettingsIcon onClick={() => editRoute(route)} />
                            </IconButton>
                            <IconButton className='text-success' title='Copy' aria-label="Copy">
                                <ControlPointDuplicateIcon onClick={() => duplicateRoute(route)} />
                            </IconButton>
                            <IconButton className='text-danger' title='Delete' aria-label='Delete'>
                                <DeleteIcon onClick={() => deleteRoute(route)} />
                            </IconButton>
                            <CSVLink
                                data={allBookingsByRoute.filter((booking) => booking.status === "Confirmed").map((booking) => {
                                    return {
                                        ...booking,
                                        male: booking.gender === 'Male' ? 'X' : '',
                                        female: booking.gender === 'Female' ? 'X' : '',
                                        dateBirth: !booking.dateBirth ? '' : new Date(booking.dateBirth).toLocaleDateString('en-US', {
                                            year: 'numeric',
                                            month: '2-digit',
                                            day: '2-digit',
                                            timeZone: 'UTC'
                                        })
                                    }
                                })}
                                headers={headers}
                                separator={";"}
                                filename={`${route.name}_pasajeros.csv`}>
                                <IconButton className='text-success' title='Export' aria-label="Export">
                                    <BrowserUpdatedIcon />
                                </IconButton>
                            </CSVLink>
                        </>
                    )}
                    <IconButton className='text-info' title='Book' aria-label="Book" disabled={route.status !== 'OPEN'}>
                        <GroupAddIcon onClick={() => addBooking(route)} />
                    </IconButton>
                    <IconButton className='text-info' title='Bookings' aria-label="Bookings">
                        <ListIcon onClick={() => navigate('/travel/bookings')} />
                    </IconButton>

                    <ExpandMore
                        expand={expanded}
                        onClick={handleExpandClick}
                        aria-expanded={expanded}
                        aria-label="show more"
                    >
                        <ExpandMoreIcon />
                    </ExpandMore>
                </CardActions>
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                    <CardContent className='border rounded m-2'>
                        {route.itinerary.map((item) => (
                            <div className="container my-4">
                                <div className="row">
                                    <div className="col-lg-1 mb-2 d-flex align-items-center">
                                        {item.airlineLogo && <img style={{ height: 'auto', maxWidth: '100%' }} src={getFileS3Url(item.airlineLogo)} />}
                                    </div>
                                    <div className="col-lg-3 d-flex align-items-center">
                                        <div className="vr me-2"></div>
                                        <div className=''>
                                            <small className='text-secondary fw-bold fst-italic'><FormattedDate month='long' day='numeric' timeZone={"UTC"} value={new Date(item.departureAt * 1000)} />, <FormattedTime hour12={true} timeZone={"UTC"} value={new Date(item.departureAt * 1000)} /></small>
                                            <br />
                                            <small className='fw-bold'>{item.departureAirportName}, {item.departureCity}, {item.departureCountry} ({item.departureAirport})</small>
                                        </div>
                                    </div>
                                    <div className="col p-2">
                                        <div style={{ width: '100%' }} className='d-flex align-content-center'>
                                            <IconButton aria-label="flight" className='p-2'>
                                                <FlightTakeoffIcon />
                                            </IconButton>
                                            <div style={{
                                                width: '100%',
                                                height: '60px',
                                                background: 'transparent',
                                                border: 'none',
                                                borderTop: 'dashed 2px orange',
                                                padding: '40px 40px',
                                                borderRadius: '50%',
                                            }} />
                                            <IconButton aria-label="flight" className='ml-auto p-2'>
                                                <FlightLandIcon />
                                            </IconButton>
                                        </div>

                                    </div>
                                    <div className="col-lg-3 d-flex align-items-center">
                                        <div className="vr me-2"></div>
                                        <div className=''>
                                            <small className='text-secondary fw-bold fst-italic'><FormattedDate month='long' day='numeric' timeZone={"UTC"} value={new Date(item.arrivalAt * 1000)} />, <FormattedTime hour12={true} timeZone={"UTC"} value={new Date(item.arrivalAt * 1000)} /></small>
                                            <br />
                                            <small className='fw-bold '>{item.arrivalAirportName}, {item.arrivalCity}, {item.arrivalCountry} ({item.arrivalAirport})</small>
                                        </div>
                                    </div>
                                </div>
                                <div className='my-3 px-5'>
                                    <div style={{ borderTop: '1px dashed rgb(159 161 161)' }} />
                                </div>
                            </div>
                        ))}
                    </CardContent>
                </Collapse>
            </Card>
        </>
    );
}

export default RouteItemCard;