import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useState, useEffect } from "react";
import { FormattedMessage } from 'react-intl';
import { useSelector, useDispatch } from "react-redux";
import { Formik } from 'formik';
import * as yup from "yup";
import { requestedCreateAirport, requestedUpdateAirport, selectAirportsStatus } from '../_redux/airportsSlice';


function CreateEditAirportModal(props) {
    const { isOpen, closeModal, closeModalRefresh, edit, item } = props;
    const dispatch = useDispatch();

    const airportStatus = useSelector((state) => selectAirportsStatus(state));

    useEffect(() => {
        if (airportStatus === 'submitted') {
            closeModalRefresh();
        }
    }, [airportStatus]);

    const schema = yup.object().shape({
        code: yup.string().required(),
        name: yup.string().required(),
        city: yup.string().required(),
        country: yup.string().required(),
    });

    const handleSubmitForm = (values, actions) => {
        if (edit) {
            dispatch(requestedUpdateAirport(values));
        } else {
            dispatch(requestedCreateAirport(values));
        }
        actions.setSubmitting(false);
    };

    return (
        <Formik
            validationSchema={schema}
            onSubmit={handleSubmitForm}
            initialValues={edit ? item : {
                name: '',
                code: '',
                city: '',
                country: '',
            }}
        >
            {({
                handleSubmit,
                handleChange,
                handleBlur,
                isSubmitting,
                values,
                touched,
                isValid,
                errors,
            }) => (
                <Modal show={isOpen} onHide={closeModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <FormattedMessage id={edit ? "Travel.edit_airport" : "Travel.new_airport"} />
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form noValidate onSubmit={handleSubmit}>
                            <Form.Group className="mb-3">
                                <Form.Label>
                                    <FormattedMessage id="Labels.code" />
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    name="code"
                                    onChange={handleChange}
                                    value={values.code}
                                    isValid={touched.code && !errors.code}
                                    isInvalid={!!errors.code}
                                />
                                <Form.Control.Feedback />
                                <Form.Control.Feedback type="invalid" />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>
                                    <FormattedMessage id="Labels.name" />
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    name="name"
                                    onChange={handleChange}
                                    value={values.name}
                                    isValid={touched.name && !errors.name}
                                    isInvalid={!!errors.name}
                                />
                                <Form.Control.Feedback />
                                <Form.Control.Feedback type="invalid" />
                            </Form.Group>
                            <Row className="mb-3">
                                <Form.Group as={Col} md="6">
                                    <Form.Label>
                                        <FormattedMessage id="Labels.city" />
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="city"
                                        onChange={handleChange}
                                        value={values.city}
                                        isValid={touched.city && !errors.city}
                                        isInvalid={!!errors.city}
                                    />
                                    <Form.Control.Feedback />
                                    <Form.Control.Feedback type="invalid" />
                                </Form.Group>
                                <Form.Group as={Col} md="6">
                                    <Form.Label>
                                        <FormattedMessage id="Labels.country" />
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="country"
                                        onChange={handleChange}
                                        value={values.country}
                                        isValid={touched.country && !errors.country}
                                        isInvalid={!!errors.country}
                                    />
                                    <Form.Control.Feedback />
                                    <Form.Control.Feedback type="invalid" />
                                </Form.Group>
                            </Row>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={closeModal}>
                            <FormattedMessage id="Actions.close" />
                        </Button>
                        <Button variant="primary" disabled={!isValid || isSubmitting} onClick={handleSubmit}>
                            {(isSubmitting || airportStatus === 'loading') && (
                                <Spinner
                                    as="span"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    animation="border"
                                />
                            )}
                            <FormattedMessage id="Actions.save" />
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}
        </Formik>
    );
}

export default CreateEditAirportModal;