import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import Modal from 'react-bootstrap/Modal';
import { useState, useEffect } from "react";
import { useIntl, FormattedMessage } from 'react-intl';
import { useSelector, useDispatch } from "react-redux";
import { requestedDeletePayment, selectAccountStatus } from '../_redux/accountSlice';

function DeletePayment(props) {
    const { isOpen, closeModal, closeModalRefresh, payment } = props;
    const dispatch = useDispatch();
    const intl = useIntl();

    const accountStatus = useSelector((state) => selectAccountStatus(state));

    useEffect(() => {
        if (accountStatus === 'deleted') {
            closeModalRefresh();
        }
    }, [accountStatus]);

    const handleSubmit = () => {
        dispatch(requestedDeletePayment(payment));
    };

    return (
        <Modal show={isOpen} onHide={closeModal}>
            <Modal.Header closeButton>
                <Modal.Title>
                    <FormattedMessage id="Actions.delete" />
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <FormattedMessage id="Label.delete_payment_message" values={{ amount: intl.formatNumber(payment.amount, { style: "currency", currency: "USD" }), userFullName: payment.userFullName }} />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={closeModal}>
                    <FormattedMessage id="Actions.close" />
                </Button>
                <Button variant="danger" disabled={accountStatus === 'loading'} onClick={handleSubmit}>
                    {accountStatus === 'loading' && (
                        <Spinner
                            as="span"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            animation="border"
                        />
                    )}
                    <FormattedMessage id="Actions.delete" />
                </Button>
            </Modal.Footer>
        </Modal >
    );
}

export default DeletePayment;