import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import { persistReducer } from 'redux-persist';
import thunk from 'redux-thunk';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web

import airlinesReducer from "../components/Travel/_redux/airlinesSlice";
import routesReducer from "../components/Travel/_redux/routesSlice";
import airportReducer from "../components/Travel/_redux/airportsSlice";
import accountReducer from "../components/Account/_redux/accountSlice";
import bookingsReducer from "../components/Travel/_redux/bookingsSlice";

const rootReducer = combineReducers({
  airlines: airlinesReducer,
  routes: routesReducer,
  airports: airportReducer,
  bookings: bookingsReducer,
  account: accountReducer,
});

const persistConfig = {
  key: 'root',
  storage,
}

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: [thunk]
});

export default store;
