import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import Skeleton from '@mui/material/Skeleton';
import { useState, useEffect } from "react";
import { useIntl, FormattedMessage, FormattedNumber, FormattedDate, FormattedTime } from 'react-intl';
import { useSelector, useDispatch } from "react-redux";
import Divider from '@mui/material/Divider';
import Avatar from '@mui/material/Avatar';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import CreateEditZellePaymentModal from '../_subs/CreateEditZellePaymentModal';
import PendingTransactionItemCard from '../_subs/PendingTransactionItemCard';
import { selectAccountInfo, requestedAccountInfo, requestedMyAccountTransactions, requestedMyPendingTransactions, selectAccountStatus } from '../_redux/accountSlice';

function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function stringAvatar(name) {
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    //children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
  };
}

function Profile(props) {
  const intl = useIntl()
  const dispatch = useDispatch();
  const accountInfo = useSelector((state) => selectAccountInfo(state));
  const accountStatus = useSelector((state) => selectAccountStatus(state));
  const [loading, setLoading] = useState(true);
  const [showNew, setShowNew] = useState(false);

  useEffect(() => {
    refreshTable();
  }, []);

  const refreshTable = () => {
    dispatch(requestedMyAccountTransactions());
    dispatch(requestedMyPendingTransactions());
    dispatch(requestedAccountInfo());
    setLoading(false);
  }

  const columns = [
    {
      field: 'confirmedAt', headerName: intl.formatMessage({ id: 'Labels.date' }), width: 180, renderCell: (params) => (
        <>
          <div className='d-flex flex-column'>
            <span className="text-secondary fw-bold fst-italic">
              <small><FormattedDate month='long' day='numeric' year='numeric' value={new Date(params.value * 1000)} /></small>
            </span>
            <span className="text-secondary fw-bold fst-italic">
              <small><FormattedTime hour12={true} value={new Date(params.value * 1000)} /></small>
            </span>
          </div>
        </>
      )
    },
    { field: 'type', headerName: intl.formatMessage({ id: 'Labels.transaction' }), width: 120, renderCell: (params) => (<span className={`text-capitalize ${params.value === 'payment' ? 'badge bg-success' : 'badge bg-danger'}`}>{params.value}</span>) },
    { field: 'paymentType', headerName: intl.formatMessage({ id: 'Labels.type' }), width: 120, renderCell: (params) => (params.row.type === 'payment' ? <span>{params.row.paymentType}</span> : '') },
    {
      field: '', headerName: intl.formatMessage({ id: 'Labels.details' }), minWidth: 200, flex: 1, renderCell: (params) => (params.row.type === 'payment' ? (
        <>
          <div className='d-flex flex-column'>
            <span className="fw-bold" style={{ color: '#005198' }}>
              <small>{params.row.sender}</small>
            </span>
            <span className="text-secondary fw-bold fst-italic">
              <small>{params.row.transactionNumber}</small>
              {params.row.note && (
                <small> | {params.row.note}</small>
              )}
            </span>
          </div>
        </>
      ) : '')
    },
    {
      field: 'amount', align: 'right', headerName: intl.formatMessage({ id: 'Labels.amount' }), width: 120, renderCell: (params) => (
        <div className='d-flex flex-column'>
          <span className="fw-bold"> <FormattedNumber value={params.value} style="currency" currency="USD" /></span>
          <span className="text-secondary fw-bold fst-italic">
            <small><FormattedNumber value={params.row.balance} style="currency" currency="USD" /></small>
          </span>
        </div>
      )
    },
    { field: 'status', headerName: intl.formatMessage({ id: 'Labels.status' }), width: 250, renderCell: (params) => (<span className={params.value === 'Pending' ? 'badge bg-warning text-dark' : (params.value === 'Confirmed' ? 'badge bg-info text-dark' : 'badge bg-danger')}>{params.value}</span>) },
  ];

  const handleShowNew = () => setShowNew(true);
  const handleCloseModals = () => {
    setShowNew(false);
  };

  const handleCloseRefresh = () => {
    handleCloseModals();
    refreshTable();
  };

  return (
    <>
      <Container>
        <div className='row border rounded-3 p-3 mx-1'>
          <div className='d-flex col-lg-3 align-items-center'>
            <Avatar {...stringAvatar(accountInfo.session.attributes.name)} >
              <AccountCircleIcon />
            </Avatar>
            <span className="m-2 fw-bold text-wrap" style={{ color: '#005198' }}>{accountInfo.session.attributes.name}</span>
          </div>
          <div className='col-lg-7 my-2'>
            <span className="text-secondary fw-bold text-wrap">{accountInfo.session.attributes.email}</span>
          </div>
          <div className='col-lg-2 justify-content-end d-flex align-items-center'>
            <span className="fw-bold text-wrap badge rounded-pill bg-warning text-dark p-3"><FormattedNumber value={accountInfo?.profile?.credit || 0} style="currency" currency="USD" /></span>
          </div>
        </div>
        <Divider className='text-uppercase fw-bold my-3'><FormattedMessage id="Labels.transactions" /></Divider>
        <div style={{ marginBottom: 5, width: '100%' }} className="d-flex justify-content-end">
          <Button variant="primary" onClick={handleShowNew}>
            <FormattedMessage
              id="Actions.new_payment"
            />
          </Button>
        </div>
        {
          accountInfo?.pendingTransactions?.length > 0 && (
            <div className='mb-1'>
              <Divider className='text-capitalize fw-bold my-1'><FormattedMessage id="Labels.pending" /></Divider>
              {
                accountInfo.pendingTransactions.map((row) => (
                  <PendingTransactionItemCard key={row.id} transaction={row} isAdmin={false} closeModalRefresh={handleCloseRefresh} />
                ))
              }
            </div>
          )
        }
        <Divider className='text-capitalize fw-bold my-1'><FormattedMessage id="Labels.confirmed" /></Divider>

        <div style={{ height: 700, width: '100%' }} className="mb-5">
          {(loading || accountStatus === 'loading') ? (
            <>
              <Skeleton height={60} />
              <Skeleton animation="wave" height={60} />
              <Skeleton animation={false} height={60} />
            </>
          ) : (
            <DataGrid
              rows={accountInfo.confirmedTransactions}
              columns={columns}
              //pageSize={50}
              rowsPerPageOptions={[5, 10, 50, 100, 500, 1000]}
            />
          )}
        </div >
      </Container>

      {(showNew) &&
        <CreateEditZellePaymentModal
          closeModal={handleCloseModals}
          closeModalRefresh={handleCloseRefresh}
          isOpen={showNew}
          edit={false}
          item={undefined}
        />
      }

    </>
  )
}

export default Profile;