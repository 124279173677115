import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import Modal from 'react-bootstrap/Modal';
import { useState, useEffect } from "react";
import { FormattedMessage } from 'react-intl';
import { useSelector, useDispatch } from "react-redux";
import { requestedProcessBooking, selectBookingsStatus, selectBookingsError } from '../_redux/bookingsSlice';

function ProcessBookingModal(props) {
    const { isOpen, closeModal, closeModalrefresh, id } = props;
    const dispatch = useDispatch();

    const bookingStatus = useSelector((state) => selectBookingsStatus(state));
    const bookingError = useSelector((state) => selectBookingsError(state));

    useEffect(() => {
        if (bookingStatus === 'processed') {
            closeModalrefresh();
        }
    }, [bookingStatus]);

    const handleSubmit = () => {
        dispatch(requestedProcessBooking(id));
    };

    return (
        <Modal show={isOpen} onHide={closeModal}>
            <Modal.Header closeButton>
                <Modal.Title>
                    <FormattedMessage id="Travel.process_booking" />
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {bookingStatus === 'error' && bookingError === 'No credit.' ? (
                    <p className='text-danger'><FormattedMessage id="Label.error_enough_balance" /></p>
                ) : (<></>)
                }
                {bookingStatus === 'error' && bookingError === 'No availability.' ? (
                    <p className='text-danger'><FormattedMessage id="Label.error_no_availability" /></p>
                ) : (<></>)
                }
                <FormattedMessage id="Travel.process_booking_message" />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={closeModal}>
                    <FormattedMessage id="Actions.close" />
                </Button>
                <Button variant="danger" disabled={bookingStatus === 'loading'} onClick={handleSubmit}>
                    {bookingStatus === 'loading' && (
                        <Spinner
                            as="span"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            animation="border"
                        />
                    )}
                    <FormattedMessage id="Actions.process" />
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default ProcessBookingModal;