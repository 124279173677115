import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from "react-redux";
import { Formik } from 'formik';
import * as yup from "yup";
import AWS from 'aws-sdk';
import { v4 as uuidv4 } from 'uuid';

const userPoolId = 'us-east-1_bwjXklaEw';

AWS.config.region = 'us-east-1';
const cognito = new AWS.CognitoIdentityServiceProvider();


function CreateUserModal(props) {
    const { isOpen, closeModal, closeModalRefresh } = props;

    const schema = yup.object().shape({
        name: yup.string().required(),
        email: yup.string().email().required(),
        group: yup.string().required(),
    });

    const handleSubmitForm = (values, actions) => {
        createUser(values, actions);
    };

    const createUser = (user, actions) => {
        let userId = uuidv4();
        var params = {
            UserPoolId: userPoolId, /* required */
            Username: user.email, /* required */
            DesiredDeliveryMediums: [
                "EMAIL"
            ],
            ForceAliasCreation: false,
            TemporaryPassword: 'FRSolTravel1',
            UserAttributes: [
                {
                    Name: 'name', /* required */
                    Value: user.name
                },
                {
                    Name: 'email', /* required */
                    Value: user.email
                },
                {
                    Name: 'email_verified', /* required */
                    Value: 'True'
                },
                {
                    Name: 'custom:credit',
                    Value: '0'
                }
            ],
        };
        cognito.adminCreateUser(params, function (err, data) {
            if (err) {
                console.log(err, err.stack); // an error occurred
            }
            else {
                console.log(data);           // successful response
                addUserToGroup(user, actions);
            }
        });
    }

    const addUserToGroup = (user, actions) => {
        var params = {
            GroupName: user.group, /* required */
            UserPoolId: userPoolId, /* required */
            Username: user.email /* required */
        };
        cognito.adminAddUserToGroup(params, function (err, data) {
            if (err) {
                console.log(err, err.stack); // an error occurred
            }
            else {
                actions.setSubmitting(false);
                console.log(data);           // successful response
                closeModalRefresh();
            }
        }
        );
    }

    return (
        <Formik
            validationSchema={schema}
            onSubmit={handleSubmitForm}
            initialValues={{
                name: '',
                email: '',
                group: '',
            }}
        >
            {({
                handleSubmit,
                handleChange,
                handleBlur,
                isSubmitting,
                values,
                touched,
                isValid,
                errors,
            }) => (
                <Modal show={isOpen} onHide={closeModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <FormattedMessage id={"Travel.new_user"} />
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form noValidate onSubmit={handleSubmit}>
                            <Form.Group as={Col} md="12">
                                <Form.Label>
                                    <FormattedMessage id="Labels.name" />
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    name="name"
                                    onChange={handleChange}
                                    value={values.name}
                                    isValid={touched.name && !errors.name}
                                    isInvalid={!!errors.name}
                                />
                                <Form.Control.Feedback />
                                <Form.Control.Feedback type="invalid" />
                            </Form.Group>
                            <Form.Group as={Col} md="12">
                                <Form.Label>
                                    <FormattedMessage id="Labels.email" />
                                </Form.Label>
                                <Form.Control
                                    type="email"
                                    name="email"
                                    onChange={handleChange}
                                    value={values.email}
                                    isValid={touched.email && !errors.email}
                                    isInvalid={!!errors.email}
                                />
                                <Form.Control.Feedback />
                                <Form.Control.Feedback type="invalid" />
                            </Form.Group>
                            <Form.Group as={Col} md="12">
                                <Form.Label>
                                    <FormattedMessage id="Labels.group" />
                                </Form.Label>
                                <Form.Select
                                    name='group'
                                    onChange={handleChange}
                                    value={values.group}
                                    isValid={touched.group && !errors.group}
                                    isInvalid={!!errors.group}
                                >
                                    <option value="">Select</option>
                                    <option value="Associate">Associate</option>
                                    <option value="Admin">Admin</option>
                                </Form.Select>
                                <Form.Control.Feedback />
                                <Form.Control.Feedback type="invalid" />
                            </Form.Group>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={closeModal}>
                            <FormattedMessage id="Actions.close" />
                        </Button>
                        <Button variant="primary" disabled={!isValid || isSubmitting} onClick={handleSubmit}>
                            {(isSubmitting) && (
                                <Spinner
                                    as="span"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    animation="border"
                                />
                            )}
                            <FormattedMessage id="Actions.save" />
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}
        </Formik>
    );
}

export default CreateUserModal;