import './App.css';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
// import NotFound from '../src/NotFound';
import { HelmetProvider } from 'react-helmet-async';
import Layout from './layouts/Layout';
import AssociateDashboard from './components/Dashboards/pages/AssociateDashboard';
import Airlines from './components/Travel/pages/Airlines';
import Airports from './components/Travel/pages/Airports';
import FlightRoutes from './components/Travel/pages/FlightRoutes';
import Book from './components/Travel/pages/Book';
import Bookings from './components/Travel/pages/Bookings';
import Users from './components/Account/pages/Users';
import Profile from './components/Account/pages/Profile';
import Transactions from './components/Account/pages/Transactions';
import { IntlProvider } from 'react-intl';
import * as messagesEn from './intl/en.json';
import * as messagesEs from './intl/es.json';

import { selectAccountInfo, requestedUpdateLan, requestedUpdateCurrentAuthenticatedUser } from './components/Account/_redux/accountSlice';
import { Amplify, Auth, Hub, API } from 'aws-amplify';
import awsconfig from './.aws-config/awsconfig'
import awsauth from './.aws-config/awsauth'


function App() {
  const dispatch = useDispatch();
  const accountInfo = useSelector((state) => selectAccountInfo(state));

  Amplify.configure(awsconfig)
  Auth.configure({ oauth: awsauth })


  useEffect(() => {
    Auth.currentAuthenticatedUser().then(user => {
      dispatch(requestedUpdateCurrentAuthenticatedUser({attributes: user.attributes, signInUserSession: user.signInUserSession}));
    }).catch((err) => {
      console.log('tete: ', err)
      Auth.federatedSignIn()
    });

    if (!accountInfo?.lan) {
      const language =
        (navigator.languages && navigator.languages[0]) ||
        navigator.language ||
        navigator.userLanguage;

      // Split locales with a region code
      const languageWithoutRegionCode = language.toLowerCase().split(/[_-]+/)[0];

      console.log(language);
      console.log(languageWithoutRegionCode);

      const lan = language === 'es' || languageWithoutRegionCode === 'es' ? 'es' : 'en';
      dispatch(requestedUpdateLan(lan));
    }
  }, []);

  return (
    <HelmetProvider>
      <IntlProvider messages={accountInfo?.lan === 'es' ? messagesEs : messagesEn} locale={accountInfo?.lan} defaultLocale="en">
        <Layout>
          <BrowserRouter>
            <Routes>
              <Route path="/associate" element={<AssociateDashboard />} />
              <Route path="/travel/airlines" element={<Airlines />} />
              <Route path="/travel/airports" element={<Airports />} />
              <Route path="/travel/routes" element={<FlightRoutes />} />
              <Route path="/travel/book" element={<Book />} />
              <Route path="/travel/bookings" element={<Bookings />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/users" element={<Users />} />
              <Route path="/transactions" element={<Transactions />} />
              {/*<Route path="*" element={<NotFound />} />*/}
              <Route path="/" element={<Navigate to="/travel/book" />} />
            </Routes>
          </BrowserRouter>
        </Layout>
      </IntlProvider>
    </HelmetProvider>
  )
}

export default App;