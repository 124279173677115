import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import { DataGrid } from '@mui/x-data-grid';
import Skeleton from '@mui/material/Skeleton';
import AWS from 'aws-sdk';
import { useState, useEffect } from "react";
import { useIntl, FormattedMessage } from 'react-intl';
import { useSelector, useDispatch } from "react-redux";
import { requestedAirlinesList, selectAllAirlines, selectAirlinesStatus } from '../_redux/airlinesSlice';
import CreateEditAirlineModal from '../_subs/CreateEditAirlineModal';
import DeleteAirlineModal from '../_subs/DeleteAirlineModal';
import { padding } from '@mui/system';

const S3_BUCKET = 'frsolutions-travel-store';
const REGION = 'us-east-1';


AWS.config.update({
  accessKeyId: 'AKIA3WOC4JYP2IWSWBN2',
  secretAccessKey: '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'
})

const myBucket = new AWS.S3({
  params: { Bucket: S3_BUCKET },
  region: REGION,
})

function Airlines(props) {
  const intl = useIntl()
  const dispatch = useDispatch();
  const allAirlines = useSelector((state) => selectAllAirlines(state));
  const airlineStatus = useSelector((state) => selectAirlinesStatus(state));
  const [loading, setLoading] = useState(true);
  const [selectedRows, setSelectedRows] = useState([]);
  const [showNew, setShowNew] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [showDelete, setShowDelete] = useState(false);

  useEffect(() => {
    refreshTable();
  }, [props]);

  const refreshTable = () => {
    setSelectedRows([]);
    dispatch(requestedAirlinesList());
    setLoading(false);
  }

  const getFileS3Url = (fileName) => {
    const params = {
      Bucket: S3_BUCKET,
      Key: fileName
    };

    return myBucket.getSignedUrl('getObject', params);
  }

  const columns = [
    { field: 'logo', headerName: intl.formatMessage({ id: 'Labels.logo' }), width: 240, renderCell: (params) => (params.value && (<img height={"95%"} src={getFileS3Url(params.value)} />)) },
    { field: 'name', headerName: intl.formatMessage({ id: 'Labels.name' }), width: 700 },
  ];

  const handleShowEdit = () => setShowEdit(true);
  const handleShowNew = () => setShowNew(true);
  const handleCloseModals = () => {
    setShowNew(false);
    setShowEdit(false);
    setShowDelete(false);
  };

  const handleCloseNewRefresh = () => {
    handleCloseModals();
    refreshTable();
  };

  const handleShowDelete = () => setShowDelete(true);
  const handleCloseDelete = () => {
    handleCloseModals();
    refreshTable();
  };

  return (
    <>
      <Container>
        <div style={{ marginBottom: 5, width: '100%' }} className="d-flex justify-content-end">
          {selectedRows.length > 0 && (
            <Button variant="outline-danger" onClick={handleShowDelete} className="me-1">
              <FormattedMessage
                id="Actions.delete"
              />
            </Button>
          )}
          {selectedRows.length === 1 && (
            <Button variant="outline-primary" onClick={handleShowEdit} className="me-1">
              <FormattedMessage
                id="Actions.edit"
              />
            </Button>
          )}
          <Button variant="primary" onClick={handleShowNew}>
            <FormattedMessage
              id="Actions.new"
            />
          </Button>
        </div>
        <div style={{ height: 700, width: '100%' }}>
          {(loading || airlineStatus === 'loading') ? (
            <>
              <Skeleton height={60} />
              <Skeleton animation="wave" height={60} />
              <Skeleton animation={false} height={60} />
            </>
          ) : (
            <DataGrid
              rows={allAirlines}
              columns={columns}
              //pageSize={50}
              rowsPerPageOptions={[5, 10, 50, 100, 500, 1000]}
              checkboxSelection
              onSelectionModelChange={(ids) => {
                const selectedIDs = new Set(ids);
                const selectedRows = allAirlines.filter((row) =>
                  selectedIDs.has(row.id),
                );

                setSelectedRows(selectedRows);
              }}
            />
          )}
        </div >
      </Container>

      {(showNew || showEdit) &&
        <CreateEditAirlineModal
          closeModal={handleCloseModals}
          closeModalRefresh={handleCloseNewRefresh}
          isOpen={showNew || showEdit}
          edit={showEdit}
          item={showEdit ? selectedRows[0] : undefined}
        />
      }

      {showDelete &&
        <DeleteAirlineModal
          closeModal={handleCloseModals}
          closeModalrefresh={handleCloseDelete}
          isOpen={showDelete}
          ids={selectedRows.map((item) => item.airlineId)}
        />
      }

    </>
  )
}

export default Airlines;