import {
    createSlice,
    createAsyncThunk,
    createEntityAdapter
} from '@reduxjs/toolkit';
import axios from "axios";

const BOOKINGS_URL = "travel/bookings";

const bookingsAdapter = createEntityAdapter();
const initialState = bookingsAdapter.getInitialState({
    status: 'idle',
    error: ''
});


// Thunk functions
export const requestedBookingsList = createAsyncThunk('bookings/requestedBookingsList', async (noparams, { getState, rejectWithValue }) => {
    const response = await axios.get(BOOKINGS_URL, {
        headers: {
            'authorization-token': getState().account.current.session.signInUserSession.idToken.jwtToken
        }
    });

    if (response.data.success) {
        return response.data.content.map((item) => { return { ...item, id: item.bookingId }; });
    } else {
        throw rejectWithValue(response.data);
    }
});

export const requestedBookingDetailsById = createAsyncThunk('bookings/requestedBookingDetailsById', async (bookingId, { getState, rejectWithValue }) => {
    const response = await axios.get(`${BOOKINGS_URL}/${bookingId}`, {
        headers: {
            'authorization-token': getState().account.current.session.signInUserSession.idToken.jwtToken
        }
    });

    if (response.data.success) {
        return response.data.content;
    } else {
        throw rejectWithValue(response.data);
    }
});

export const requestedCreateBooking = createAsyncThunk('bookings/requestedCreateBooking', async (booking, { getState, rejectWithValue }) => {
    const response = await axios.post(`${BOOKINGS_URL}/`, booking, {
        headers: {
            'authorization-token': getState().account.current.session.signInUserSession.idToken.jwtToken
        }
    });

    if (response.data.success) {
        return response.data.content;
    } else {
        throw rejectWithValue(response.data);
    }
});

export const requestedUpdateBooking = createAsyncThunk('bookings/requestedUpdateBooking', async (booking, { getState, rejectWithValue }) => {
    delete booking.id;
    const response = await axios.put(`${BOOKINGS_URL}/${booking.bookingId}`, booking, {
        headers: {
            'authorization-token': getState().account.current.session.signInUserSession.idToken.jwtToken
        }
    });

    if (response.data.success) {
        return response.data.content;
    } else {
        throw rejectWithValue(response.data);
    }
});

export const requestedDeleteBookingById = createAsyncThunk('bookings/requestedDeleteBookingById', async (bookingId, { getState, rejectWithValue }) => {
    const response = await axios.delete(`${BOOKINGS_URL}/${bookingId}`, {
        headers: {
            'authorization-token': getState().account.current.session.signInUserSession.idToken.jwtToken
        }
    });

    if (response.data.success) {
        return response.data.content;
    } else {
        throw rejectWithValue(response.data);
    }
});

export const requestedCancelBookingById = createAsyncThunk('bookings/requestedCancelBookingById', async (bookingId, { getState, rejectWithValue }) => {
    const response = await axios.post(`${BOOKINGS_URL}/${bookingId}/cancel`, {}, {
        headers: {
            'authorization-token': getState().account.current.session.signInUserSession.idToken.jwtToken
        }
    });

    if (response.data.success) {
        return response.data.content;
    } else {
        throw rejectWithValue(response.data);
    }
});

export const requestedBookingPdf = createAsyncThunk('bookings/requestedBookingPdf', async (bookingId, { getState, rejectWithValue }) => {
    const response = await axios.get(`${BOOKINGS_URL}/${bookingId}/pdf`, {
        headers: {
            'authorization-token': getState().account.current.session.signInUserSession.idToken.jwtToken
        }
    });

    if (response.data.success) {
        return response.data.content;
    } else {
        throw rejectWithValue(response.data);
    }
});

export const requestedProcessBooking = createAsyncThunk('bookings/requestedProcessBooking', async (bookingId, { getState, rejectWithValue }) => {
    try {
        const response = await axios.post(`${BOOKINGS_URL}/${bookingId}/process`, {}, {
            headers: {
                'authorization-token': getState().account.current.session.signInUserSession.idToken.jwtToken
            }
        });
        if (response.data.success) {
            return response.data.content;
        } else {
            throw rejectWithValue(response.data);
        }
    } catch (err) {
        if (!err.response) {
            throw err
        }

        return rejectWithValue(err.response.data.content);
    }
});

const bookingsSlice = createSlice({
    name: 'bookings',
    initialState,
    reducers: {
    },
    extraReducers: builder => {
        builder
            .addCase(requestedBookingsList.pending, (state, action) => {
                state.status = 'loading';
                state.ids = [];
                state.entities = {};
                state.current = undefined;
                state.error = '';
            })
            .addCase(requestedBookingsList.fulfilled, (state, action) => {
                state.status = 'loaded';
                bookingsAdapter.addMany(state, action);
            })
            .addCase(requestedBookingsList.rejected, (state, action) => {
                state.status = 'error';
                state.error = action.payload;
            })

            .addCase(requestedBookingDetailsById.pending, (state, action) => {
                state.status = 'loading';
                state.current = undefined;
                state.errors = '';
            })
            .addCase(requestedBookingDetailsById.fulfilled, (state, action) => {
                state.status = 'loaded';
                state.current = action.payload;
            })
            .addCase(requestedBookingDetailsById.rejected, (state, action) => {
                state.status = 'error';
                state.error = action.payload;
            })

            .addCase(requestedCreateBooking.pending, (state, action) => {
                state.status = 'loading';
                state.error = '';
            })
            .addCase(requestedCreateBooking.fulfilled, (state, action) => {
                state.status = 'submitted';
            })
            .addCase(requestedCreateBooking.rejected, (state, action) => {
                state.status = 'error';
                state.error = action.payload;
            })

            .addCase(requestedUpdateBooking.pending, (state, action) => {
                state.status = 'loading';
                state.error = '';
            })
            .addCase(requestedUpdateBooking.fulfilled, (state, action) => {
                state.status = 'submitted';
            })
            .addCase(requestedUpdateBooking.rejected, (state, action) => {
                state.status = 'error';
                state.error = action.payload;
            })

            .addCase(requestedDeleteBookingById.pending, (state, action) => {
                state.status = 'loading';
                state.error = '';
            })
            .addCase(requestedDeleteBookingById.fulfilled, (state, action) => {
                state.status = 'deleted';
            })
            .addCase(requestedDeleteBookingById.rejected, (state, action) => {
                state.status = 'error';
                state.error = action.payload;
            })

            .addCase(requestedCancelBookingById.pending, (state, action) => {
                state.status = 'loading';
                state.error = '';
            })
            .addCase(requestedCancelBookingById.fulfilled, (state, action) => {
                state.status = 'cancelled';
            })
            .addCase(requestedCancelBookingById.rejected, (state, action) => {
                state.status = 'error';
                state.error = action.payload;
            })

            .addCase(requestedBookingPdf.pending, (state, action) => {
                state.status = 'loading';
                state.current = { ...state.current, pdf: undefined };
                state.error = '';
            })
            .addCase(requestedBookingPdf.fulfilled, (state, action) => {
                state.status = 'loaded';
                state.current = { ...state.current, pdf: action.payload };
            })
            .addCase(requestedBookingPdf.rejected, (state, action) => {
                state.status = 'error';
                state.error = action.payload;
            })

            .addCase(requestedProcessBooking.pending, (state, action) => {
                state.status = 'loading';
                state.error = '';
            })
            .addCase(requestedProcessBooking.fulfilled, (state, action) => {
                state.status = 'processed';
            })
            .addCase(requestedProcessBooking.rejected, (state, action) => {
                state.status = 'error';
                state.error = action.payload;
            })
    }
});

// Selectors
export const { selectAll: selectAllBookings, selectById: selectBookingById, selectIds: selectBookingsIds, selectTotal: selectTotalBookings } =
    bookingsAdapter.getSelectors(state => state.bookings);

export const selectBookingsStatus = (state) => state.bookings.status;
export const selectBookingsCurrent = (state) => state.bookings.current;
export const selectBookingsError = (state) => state.bookings.error;
export const selectBookingsByRouteId = (state, routeId) => selectAllBookings(state).filter((booking) => booking.routeId === routeId);

export default bookingsSlice.reducer