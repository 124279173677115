import {
    createSlice,
    createAsyncThunk,
    createEntityAdapter
} from '@reduxjs/toolkit';
import axios from "axios";

const ROUTES_URL = "travel/routes";

const routesAdapter = createEntityAdapter();
const initialState = routesAdapter.getInitialState({
    status: 'idle',
    errors: undefined
});


// Thunk functions
export const requestedRoutesList = createAsyncThunk('routes/requestedRoutesList', async (noparams, { getState, rejectWithValue }) => {
    const response = await axios.get(ROUTES_URL, {
        headers: {
            'authorization-token': getState().account.current.session.signInUserSession.idToken.jwtToken
        }
    });

    if (response.data.success) {
        return response.data.content.map((item) => { return { ...item, id: item.routeId }; });
    } else {
        throw rejectWithValue(response.data);
    }
});

export const requestedRouteDetailsById = createAsyncThunk('routes/requestedRouteDetailsById', async (routeId, { getState, rejectWithValue }) => {
    const response = await axios.get(`${ROUTES_URL}/${routeId}`, {
        headers: {
            'authorization-token': getState().account.current.session.signInUserSession.idToken.jwtToken
        }
    });

    if (response.data.success) {
        return response.data.content;
    } else {
        throw rejectWithValue(response.data);
    }
});

export const requestedCreateRoute = createAsyncThunk('routes/requestedCreateRoute', async (route, { getState, rejectWithValue }) => {
    const response = await axios.post(`${ROUTES_URL}/`, route, {
        headers: {
            'authorization-token': getState().account.current.session.signInUserSession.idToken.jwtToken
        }
    });

    if (response.data.success) {
        return response.data.content;
    } else {
        throw rejectWithValue(response.data);
    }
});

export const requestedUpdateRoute = createAsyncThunk('routes/requestedUpdateRoute', async (route, { getState, rejectWithValue }) => {
    delete route.id;
    const response = await axios.put(`${ROUTES_URL}/${route.routeId}`, route, {
        headers: {
            'authorization-token': getState().account.current.session.signInUserSession.idToken.jwtToken
        }
    });

    if (response.data.success) {
        return response.data.content;
    } else {
        throw rejectWithValue(response.data);
    }
});

export const requestedDeleteRouteById = createAsyncThunk('routes/requestedDeleteRouteById', async (routeId, { getState, rejectWithValue }) => {
    const response = await axios.delete(`${ROUTES_URL}/${routeId}`, {
        headers: {
            'authorization-token': getState().account.current.session.signInUserSession.idToken.jwtToken
        }
    });

    if (response.data.success) {
        return response.data.content;
    } else {
        throw rejectWithValue(response.data);
    }
});

const routesSlice = createSlice({
    name: 'routes',
    initialState,
    reducers: {
    },
    extraReducers: builder => {
        builder
            .addCase(requestedRoutesList.pending, (state, action) => {
                state.status = 'loading';
                state.ids = [];
                state.entities = {};
                state.current = undefined;
                state.errors = undefined;
            })
            .addCase(requestedRoutesList.fulfilled, (state, action) => {
                state.status = 'loaded';
                routesAdapter.addMany(state, action);
            })
            .addCase(requestedRoutesList.rejected, (state, action) => {
                state.status = 'error';
                state.errors = action.payload?.errors;
            })

            .addCase(requestedRouteDetailsById.pending, (state, action) => {
                state.status = 'loading';
                state.current = undefined;
                state.errors = undefined;
            })
            .addCase(requestedRouteDetailsById.fulfilled, (state, action) => {
                state.status = 'loaded';
                state.current = action.payload;
            })
            .addCase(requestedRouteDetailsById.rejected, (state, action) => {
                state.status = 'error';
                state.errors = action.payload?.errors;
            })

            .addCase(requestedCreateRoute.pending, (state, action) => {
                state.status = 'loading';
                state.errors = undefined;
            })
            .addCase(requestedCreateRoute.fulfilled, (state, action) => {
                state.status = 'submitted';
            })
            .addCase(requestedCreateRoute.rejected, (state, action) => {
                state.status = 'error';
                state.errors = action.payload?.errors;
            })

            .addCase(requestedUpdateRoute.pending, (state, action) => {
                state.status = 'loading';
                state.errors = undefined;
            })
            .addCase(requestedUpdateRoute.fulfilled, (state, action) => {
                state.status = 'submitted';
            })
            .addCase(requestedUpdateRoute.rejected, (state, action) => {
                state.status = 'error';
                state.errors = action.payload?.errors;
            })

            .addCase(requestedDeleteRouteById.pending, (state, action) => {
                state.status = 'loading';
                state.errors = undefined;
            })
            .addCase(requestedDeleteRouteById.fulfilled, (state, action) => {
                state.status = 'deleted';
            })
            .addCase(requestedDeleteRouteById.rejected, (state, action) => {
                state.status = 'error';
                state.errors = action.payload?.errors;
            })
    }
});

// Selectors
export const { selectAll: selectAllRoutes, selectById: selectRouteById, selectIds: selectRoutesIds, selectTotal: selectTotalRoutes } =
    routesAdapter.getSelectors(state => state.routes);

export const selectRoutesStatus = (state) => state.routes.status;
export const selectRoutesCurrent = (state) => state.routes.current;
export const selectRoutesErrors = (state) => state.routes.errors;

export default routesSlice.reducer