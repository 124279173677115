import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import Skeleton from '@mui/material/Skeleton';
import { useState, useEffect } from "react";
import { useIntl, FormattedMessage, FormattedNumber, FormattedDate, FormattedTime } from 'react-intl';
import { useSelector, useDispatch } from "react-redux";
import Divider from '@mui/material/Divider';
import PendingTransactionItemCard from '../_subs/PendingTransactionItemCard';
import { selectAllConfirmedTransactions, selectAllPendingTransactions, requestedAllConfirmedTransactions, requestedAllPendingTransactions, selectAccountStatus } from '../_redux/accountSlice';

function Transactions(props) {
  const intl = useIntl()
  const dispatch = useDispatch();
  const allConfirmedTransactions = useSelector((state) => selectAllConfirmedTransactions(state));
  const allPendingTransactions = useSelector((state) => selectAllPendingTransactions(state));
  const accountStatus = useSelector((state) => selectAccountStatus(state));
  const [loading, setLoading] = useState(true);
  const [showDelete, setShowDelete] = useState(false);

  useEffect(() => {
    refreshTable();
  }, []);

  const refreshTable = () => {
    dispatch(requestedAllConfirmedTransactions());
    dispatch(requestedAllPendingTransactions());
    setLoading(false);
  }

  const columns = [
    {
      field: 'confirmedAt', headerName: intl.formatMessage({ id: 'Labels.date' }), width: 180, renderCell: (params) => (
        <>
          <div className='d-flex flex-column'>
            <span className="text-secondary fw-bold fst-italic">
              <small><FormattedDate month='long' day='numeric' year='numeric' value={new Date(params.value * 1000)} /></small>
            </span>
            <span className="text-secondary fw-bold fst-italic">
              <small><FormattedTime hour12={true} value={new Date(params.value * 1000)} /></small>
            </span>
          </div>
        </>
      )
    },
    {
      field: 'type', headerName: intl.formatMessage({ id: 'Labels.transaction' }), width: 120, renderCell: (params) => (
        <span className={`text-capitalize ${params.value === 'payment' ? 'badge bg-success' : 'badge bg-danger'}`}>{params.value}</span>
      )
    },
    {
      field: 'userFullName', headerName: `${intl.formatMessage({ id: 'Labels.user' })} & ${intl.formatMessage({ id: 'Labels.type' })}`, width: 160, renderCell: (params) => (params.row.type === 'payment' ?
        <div className='d-flex flex-column'>
          <span className="fw-bold" style={{ color: '#005198' }}>
            <small>{params.value}</small>
          </span>
          <span className={`text-capitalize`}>{params.row.paymentType}</span>
        </div>
        : ''
      )
    },
    {
      field: '', headerName: intl.formatMessage({ id: 'Labels.details' }), minWidth: 200, flex: 1, renderCell: (params) => (params.row.type === 'payment' ? (
        <>
          <div className='d-flex flex-column'>
            <span className="fw-bold" style={{ color: '#005198' }}>
              <small>{params.row.sender}</small>
            </span>
            <span className="text-secondary fw-bold fst-italic">
              <small>{params.row.transactionNumber}</small>
              {params.row.note && (
                <small> | {params.row.note}</small>
              )}
            </span>
          </div>
        </>
      ) : '')
    },
    {
      field: 'amount', align: 'right', headerName: intl.formatMessage({ id: 'Labels.amount' }), width: 120, renderCell: (params) => (
        <div className='d-flex flex-column'>
          <span className="fw-bold"> <FormattedNumber value={params.value} style="currency" currency="USD" /></span>
          <span className="text-secondary fw-bold fst-italic">
            <small><FormattedNumber value={params.row.balance} style="currency" currency="USD" /></small>
          </span>
        </div>
      )
    },
    { field: 'status', headerName: intl.formatMessage({ id: 'Labels.status' }), width: 250, renderCell: (params) => (<span className={params.value === 'Pending' ? 'badge bg-warning text-dark' : (params.value === 'Confirmed' ? 'badge bg-info text-dark' : 'badge bg-danger')}>{params.value}</span>) },
  ];

  const handleCloseModals = () => {
    setShowDelete(false);
  };

  const handleCloseRefresh = () => {
    handleCloseModals();
    refreshTable();
  };

  const handleShowDelete = () => setShowDelete(true);

  return (
    <>
      <Container>
        {
          allPendingTransactions?.length > 0 && (
            <div className='mb-1'>
              <Divider className='text-capitalize fw-bold my-1'><FormattedMessage id="Labels.pending" /></Divider>
              {
                allPendingTransactions.map((row) => (
                  <PendingTransactionItemCard key={row.id} transaction={row} isAdmin={true} closeModalRefresh={handleCloseRefresh} />
                ))
              }
            </div>
          )
        }
        <Divider className='text-capitalize fw-bold my-1'><FormattedMessage id="Labels.confirmed" /></Divider>

        <div style={{ height: 700, width: '100%' }} className="mb-5">
          {(loading || accountStatus === 'loading') ? (
            <>
              <Skeleton height={60} />
              <Skeleton animation="wave" height={60} />
              <Skeleton animation={false} height={60} />
            </>
          ) : (
            <DataGrid
              rows={allConfirmedTransactions}
              columns={columns}
              //pageSize={50}
              rowsPerPageOptions={[5, 10, 50, 100, 500, 1000]}
            />
          )}
        </div >
      </Container>

    </>
  )
}

export default Transactions;