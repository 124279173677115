import Container from 'react-bootstrap/Container';
import { FormattedMessage } from 'react-intl';

function AssociateDashboard() {
  return (
    <Container>
      <div>
        <FormattedMessage
          id="Dashboards.welcome"
          defaultMessage="Default"
          //values={{ ts: Date.now() }}
        /></div>
    </Container>
  )
}

export default AssociateDashboard;