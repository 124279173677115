import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import Skeleton from '@mui/material/Skeleton';
import AWS from 'aws-sdk';
import { useState, useEffect } from "react";
import { useIntl, FormattedMessage, FormattedDate, FormattedTime, FormattedNumber } from 'react-intl';
import { useSelector, useDispatch } from "react-redux";
import { requestedBookingsList, selectAllBookings, selectBookingsStatus, selectBookingsCurrent, requestedBookingPdf, requestedProcessBooking } from '../_redux/bookingsSlice';
import { selectIsAdmin } from '../../Account/_redux/accountSlice';
import CreateEditBookingModal from '../_subs/CreateEditBookingModal';
import DeleteBookingModal from '../_subs/DeleteBookingModal';
import ProcessBookingModal from '../_subs/ProcessBookingModal';
import CancelBookingModal from '../_subs/CancelBookingModal';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import { useNavigate } from 'react-router-dom';
import PrintIcon from '@mui/icons-material/Print';
import CancelIcon from '@mui/icons-material/Cancel';

const S3_BUCKET = 'frsolutions-travel-store';
const REGION = 'us-east-1';


AWS.config.update({
  accessKeyId: 'AKIA3WOC4JYP2IWSWBN2',
  secretAccessKey: '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'
})

const myBucket = new AWS.S3({
  params: { Bucket: S3_BUCKET },
  region: REGION,
})

function Bookings(props) {
  const intl = useIntl()
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const allBookings = useSelector((state) => selectAllBookings(state));
  const bookingsStatus = useSelector((state) => selectBookingsStatus(state));
  const bookingDetails = useSelector((state) => selectBookingsCurrent(state));

  const [loading, setLoading] = useState(true);
  const [selectedBooking, setSelectedBooking] = useState({});
  const [showEdit, setShowEdit] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [showCancel, setShowCancel] = useState(false);
  const [showProcess, setShowProcess] = useState(false);
  const isAdmin = useSelector((state) => selectIsAdmin(state));

  useEffect(() => {
    refreshTable();
  }, [props]);

  useEffect(() => {
    if (bookingDetails?.pdf) {
      var pdfArr = base64ToArrayBuffer(bookingDetails.pdf);
      saveByteArray(`Itinerary ${selectedBooking?.name}.pdf`, pdfArr);
    }
  }, [bookingDetails]);

  function base64ToArrayBuffer(base64) {
    var binaryString = window.atob(base64);
    var binaryLen = binaryString.length;
    var bytes = new Uint8Array(binaryLen);
    for (var i = 0; i < binaryLen; i++) {
      var ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }
    return bytes;
  }

  function saveByteArray(reportName, byte) {
    var blob = new Blob([byte], { type: "application/pdf" });
    var link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    var fileName = reportName;
    link.download = fileName;
    link.click();
  };

  const refreshTable = () => {
    setSelectedBooking({});
    dispatch(requestedBookingsList());
    setLoading(false);
  }

  const getFileS3Url = (fileName) => {
    const params = {
      Bucket: S3_BUCKET,
      Key: fileName
    };

    return myBucket.getSignedUrl('getObject', params);
  }

  const columns = [
    //{ field: 'route', headerName: intl.formatMessage({ id: 'Labels.route' }), width: 240, renderCell: (params) => (params.value && (<img height={"95%"} src={getFileS3Url(params.value)} />)) },
    {
      field: 'createdAt', headerName: intl.formatMessage({ id: 'Labels.booked' }), width: 180, renderCell: (params) => (
        <>
          <div className='d-flex flex-column'>
            <span className="fw-bold" style={{ color: '#005198' }}>
              <small>{params.row.userFullName}</small>
            </span>
            <span className="text-secondary fw-bold fst-italic">
              <small><FormattedDate month='numeric' day='numeric' year='numeric' value={new Date(params.value * 1000)} />, <FormattedTime hour12={true} value={new Date(params.value * 1000)} /></small>
            </span>
          </div>
        </>
      )
    },
    {
      field: 'route', headerName: intl.formatMessage({ id: 'Labels.flight' }), minWidth: 200, flex: 1, renderCell: (params) => {
        let itinerarySummary = '';
        let route = params.value;
        if (route.itinerary) {
          for (const item of route.itinerary) {
            itinerarySummary += item.departureAirport + " | ";
          }
          itinerarySummary += route.itinerary[route.itinerary.length - 1].arrivalAirport;
        }
        return (
          <>
            <div className='d-flex flex-column'>
              <span className="fw-bolder" style={{ color: '#005198' }}>{itinerarySummary}</span>
              <span className="text-secondary fw-bold fst-italic">
                <FormattedDate month='long' day='numeric' timeZone={"UTC"} value={new Date(route.itinerary[0].departureAt * 1000)} />, <FormattedTime hour12={true} timeZone={"UTC"} value={new Date(route.itinerary[0].departureAt * 1000)} />
              </span>
            </div>
          </>
        );

      }
    },
    { field: 'name', headerName: intl.formatMessage({ id: 'Labels.name' }), minWidth: 200, flex: 1 },
    { field: 'passport', headerName: intl.formatMessage({ id: 'Labels.passport' }), width: 120 },
    { field: 'bookingNumber', headerName: intl.formatMessage({ id: 'Labels.booking' }) + ' #', width: 120 },
    { field: 'note', headerName: intl.formatMessage({ id: 'Labels.note' }), width: 200 },
    { field: 'status', headerName: intl.formatMessage({ id: 'Labels.status' }), width: 120, renderCell: (params) => (<span className={params.value === 'Unconfirmed' ? 'badge bg-warning text-dark' : (params.value === 'Confirmed' ? 'badge bg-info text-dark' : (params.value === 'Cancelled' ? 'badge bg-danger' : 'badge bg-success'))}>{params.value}</span>) },
    {
      field: 'actions',
      type: 'actions',
      width: 160,
      getActions: (params) => [
        params.row.status === 'Unconfirmed' ? (
          <GridActionsCellItem style={{ color: '#2987cf' }} icon={<ThumbUpAltIcon onClick={() => {
            handleShowProcess(params.row);
          }
          } />} label="Process" />
        ) : (<></>),
        <GridActionsCellItem style={{ color: 'gray' }} icon={<PrintIcon onClick={() => {
          handleDownloadBookingPDF(params.row);
        }} />} label="PDF" />,
        params.row.status === 'Unconfirmed' || isAdmin ? (
          <GridActionsCellItem style={{ color: '#2987cf' }} icon={<EditIcon onClick={() => {
            handleShowEdit(params.row);
          }} />} label="Edit" />
        ) : (<></>),
        params.row.status === 'Unconfirmed' || (isAdmin && params.row.status === 'Cancelled') ? (
          <GridActionsCellItem style={{ color: 'red' }} icon={<DeleteIcon onClick={() => {
            handleShowDelete(params.row);
          }
          } />} label="Delete" />
        ) : (<></>),
        isAdmin && (params.row.status === 'Processed' || params.row.status === 'Confirmed') ? (
          <GridActionsCellItem style={{ color: 'red' }} icon={<CancelIcon onClick={() => {
            handleShowCancel(params.row);
          }
          } />} label="Cancel" />
        ) : (<></>),
      ],
    }
  ];

  const handleShowEdit = (booking) => {
    setSelectedBooking(booking);
    setShowEdit(true);
  }
  const handleCloseModals = () => {
    setShowEdit(false);
    setShowDelete(false);
    setShowProcess(false);
    setShowCancel(false);
  };

  const handleCloseNewRefresh = () => {
    handleCloseModals();
    refreshTable();
  };

  const handleShowDelete = (booking) => {
    setSelectedBooking(booking);
    setShowDelete(true);
  }

  const handleShowCancel = (booking) => {
    setSelectedBooking(booking);
    setShowCancel(true);
  }

  const handleShowProcess = (booking) => {
    setSelectedBooking(booking);
    setShowProcess(true);
  }

  const handleDownloadBookingPDF = (booking) => {
    setSelectedBooking(booking);
    dispatch(requestedBookingPdf(booking.bookingId));
  }

  return (
    <>
      <Container>
        <div style={{ marginBottom: 5, width: '100%' }} className="d-flex justify-content-end">
          <Button variant="primary" onClick={() => navigate('/travel/book')}>
            <FormattedMessage
              id="Actions.new"
            />
          </Button>
        </div>
        <div style={{ height: 700, width: '100%' }}>
          {(loading || bookingsStatus === 'loading') ? (
            <>
              <Skeleton height={60} />
              <Skeleton animation="wave" height={60} />
              <Skeleton animation={false} height={60} />
            </>
          ) : (
            <DataGrid
              rows={allBookings}
              columns={columns}
              //pageSize={50}
              rowsPerPageOptions={[5, 10, 50, 100, 500, 1000]}
            />
          )}
        </div >
      </Container>

      {showEdit &&
        <CreateEditBookingModal
          closeModal={handleCloseModals}
          closeModalRefresh={handleCloseNewRefresh}
          isOpen={showEdit}
          edit={showEdit}
          item={selectedBooking}
        />
      }

      {showDelete &&
        <DeleteBookingModal
          closeModal={handleCloseModals}
          closeModalrefresh={handleCloseNewRefresh}
          isOpen={showDelete}
          id={selectedBooking.bookingId}
        />
      }

      {showProcess &&
        <ProcessBookingModal
          closeModal={handleCloseModals}
          closeModalrefresh={handleCloseNewRefresh}
          isOpen={showProcess}
          id={selectedBooking.bookingId}
        />
      }

      {showCancel &&
        <CancelBookingModal
          closeModal={handleCloseModals}
          closeModalrefresh={handleCloseNewRefresh}
          isOpen={showCancel}
          id={selectedBooking.bookingId}
        />
      }

    </>
  )
}

export default Bookings;