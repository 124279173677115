import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Skeleton from '@mui/material/Skeleton';
import { useState, useEffect } from "react";
import { useIntl, FormattedMessage } from 'react-intl';
import { useSelector, useDispatch } from "react-redux";
import { requestedRoutesList, selectAllRoutes, selectRoutesStatus } from '../_redux/routesSlice';
import { requestedBookingsList } from '../_redux/bookingsSlice';
import CreateEditRouteModal from '../_subs/CreateEditRouteModal';
import CreateEditBookingModal from '../_subs/CreateEditBookingModal';
import DeleteRouteModal from '../_subs/DeleteRouteModal';
import RouteItemCard from '../_subs/RouteItemCard';
import { useNavigate } from 'react-router-dom';

function FlightRoutes(props) {
  const intl = useIntl();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const allRoutes = useSelector((state) => selectAllRoutes(state));
  const routeStatus = useSelector((state) => selectRoutesStatus(state));
  const [loading, setLoading] = useState(true);
  const [selectedItem, setSelectedItem] = useState();
  const [showNew, setShowNew] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [showDuplicate, setShowDuplicate] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [showAddBooking, setShowAddBooking] = useState(false);

  useEffect(() => {
    refreshTable();
    dispatch(requestedBookingsList());
  }, [props]);

  const refreshTable = () => {
    setSelectedItem({});
    dispatch(requestedRoutesList());
    setLoading(false);
  }

  const handleShowEdit = (route) => {
    setSelectedItem(route);
    setShowEdit(true);
  }
  const handleShowNew = () => setShowNew(true);
  const handleCloseModals = () => {
    setShowNew(false);
    setShowEdit(false);
    setShowDelete(false);
    setShowDuplicate(false);
    setShowAddBooking(false);
    refreshTable();
  };

  const handleCloseNewRefresh = () => {
    handleCloseModals();
    refreshTable();
  };

  const handleCloseNewBookRefresh = () => {
    handleCloseModals();
    navigate('/travel/bookings');
  };

  const handleShowDelete = (route) => {
    setSelectedItem(route);
    setShowDelete(true);
  }
  const handleCloseDelete = () => {
    handleCloseModals();
    refreshTable();
  };

  const handleShowDuplicate = (route) => {
    setSelectedItem({ ...route, routeId: '', id: undefined, sk: undefined, pk: undefined, createdAt: undefined, updatedAt: undefined });
    setShowDuplicate(true);
  };

  const handleShowAddBooking = (route) => {
    setSelectedItem(route);
    setShowAddBooking(true);
  }

  return (
    <>
      <Container>
        <div style={{ marginBottom: 5, width: '100%' }} className="d-flex justify-content-end">
          <Button variant="primary" onClick={handleShowNew}>
            <FormattedMessage
              id="Actions.new"
            />
          </Button>
        </div>
        <div className='mb-5'>
          {(loading || routeStatus === 'loading') ? (
            <>
              <Skeleton height={60} />
              <Skeleton animation="wave" height={60} />
              <Skeleton animation={false} height={60} />
            </>
          ) : (
            allRoutes.map((row) => (
              <RouteItemCard key={row.routeId} route={row} deleteRoute={handleShowDelete} editRoute={handleShowEdit} duplicateRoute={handleShowDuplicate} booking={false} addBooking={handleShowAddBooking} />
            ))
          )}
        </div >
      </Container>

      {(showNew || showEdit || showDuplicate) &&
        <CreateEditRouteModal
          closeModal={handleCloseModals}
          closeModalRefresh={handleCloseNewRefresh}
          isOpen={showNew || showEdit || showDuplicate}
          edit={showEdit}
          duplicate={showDuplicate}
          item={selectedItem}
        />
      }

      {showAddBooking &&
        <CreateEditBookingModal
          closeModal={handleCloseModals}
          closeModalRefresh={handleCloseNewBookRefresh}
          isOpen={showAddBooking}
          edit={false}
          route={selectedItem}
        />
      }

      {showDelete &&
        <DeleteRouteModal
          closeModal={handleCloseModals}
          closeModalrefresh={handleCloseDelete}
          isOpen={showDelete}
          id={selectedItem.routeId}
        />
      }

    </>
  )
}

export default FlightRoutes;