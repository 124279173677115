import {
    createSlice,
    createAsyncThunk,
    createEntityAdapter
} from '@reduxjs/toolkit';
import axios from "axios";

const ACCOUNT_URL = "accounts";

const accountAdapter = createEntityAdapter();
const initialState = accountAdapter.getInitialState({
    status: 'idle',
    current: {
        lan: "ES"
    },
    errors: undefined
});


// Thunk functions
export const requestedAccountInfo = createAsyncThunk('account/requestedAccountInfo', async (noparams, { getState, rejectWithValue }) => {
    const currentUserId = getState().account.current.session.attributes.sub;
    const response = await axios.get(`${ACCOUNT_URL}/${currentUserId}`, {
        headers: {
            'authorization-token': getState().account.current.session.signInUserSession.idToken.jwtToken
        }
    });

    if (response.data.success) {
        return response.data.content;
    } else {
        throw rejectWithValue(response.data);
    }
});

export const requestedUpdateLan = createAsyncThunk('account/requestedUpdateLan', async (languaje, { getState, rejectWithValue }) => {
    return languaje;
});

export const requestedUpdateCurrentAuthenticatedUser = createAsyncThunk('account/requestedUpdateCurrentAuthenticatedUser', async (user, { getState, rejectWithValue }) => {
    return user;
});

export const requestedMyAccountTransactions = createAsyncThunk('account/requestedMyAccountTransactions', async (noparams, { getState, rejectWithValue }) => {
    const currentUserId = getState().account.current.session.attributes.sub;
    const response = await axios.get(`${ACCOUNT_URL}/${currentUserId}/transactions/confirmed`, {
        headers: {
            'authorization-token': getState().account.current.session.signInUserSession.idToken.jwtToken
        }
    });

    if (response.data.success) {
        return response.data.content.map((item) => { return { ...item, id: item.paymentId }; });
    } else {
        throw rejectWithValue(response.data);
    }
});

export const requestedMyPendingTransactions = createAsyncThunk('account/requestedMyPendingTransactions', async (noparams, { getState, rejectWithValue }) => {
    const currentUserId = getState().account.current.session.attributes.sub;
    const response = await axios.get(`${ACCOUNT_URL}/${currentUserId}/transactions/pending`, {
        headers: {
            'authorization-token': getState().account.current.session.signInUserSession.idToken.jwtToken
        }
    });

    if (response.data.success) {
        return response.data.content.map((item) => { return { ...item, id: item.paymentId }; });
    } else {
        throw rejectWithValue(response.data);
    }
});

export const requestedAllConfirmedTransactions = createAsyncThunk('account/requestedAllConfirmedTransactions', async (noparams, { getState, rejectWithValue }) => {
    const response = await axios.get(`${ACCOUNT_URL}/transactions/confirmed`, {
        headers: {
            'authorization-token': getState().account.current.session.signInUserSession.idToken.jwtToken
        }
    });

    if (response.data.success) {
        return response.data.content.map((item) => { return { ...item, id: item.paymentId }; });
    } else {
        throw rejectWithValue(response.data);
    }
});

export const requestedAllPendingTransactions = createAsyncThunk('account/requestedAllPendingTransactions', async (noparams, { getState, rejectWithValue }) => {
    const response = await axios.get(`${ACCOUNT_URL}/transactions/pending`, {
        headers: {
            'authorization-token': getState().account.current.session.signInUserSession.idToken.jwtToken
        }
    });

    if (response.data.success) {
        return response.data.content.map((item) => { return { ...item, id: item.paymentId }; });
    } else {
        throw rejectWithValue(response.data);
    }
});

export const requestedCreatePayment = createAsyncThunk('airports/requestedCreatePayment', async (payment, { getState, rejectWithValue }) => {
    const currentUserId = payment.userId || getState().account.current.session.attributes.sub;
    const response = await axios.post(`${ACCOUNT_URL}/${currentUserId}/payments`, payment, {
        headers: {
            'authorization-token': getState().account.current.session.signInUserSession.idToken.jwtToken
        }
    });

    if (response.data.success) {
        return response.data.content;
    } else {
        throw rejectWithValue(response.data);
    }
});

export const requestedConfirmPayment = createAsyncThunk('airports/requestedConfirmPayment', async (payment, { getState, rejectWithValue }) => {
    const response = await axios.post(`${ACCOUNT_URL}/${payment.accountId}/payments/${payment.id}/confirm`, {}, {
        headers: {
            'authorization-token': getState().account.current.session.signInUserSession.idToken.jwtToken
        }
    });

    if (response.data.success) {
        return response.data.content;
    } else {
        throw rejectWithValue(response.data);
    }
});

export const requestedUpdatePayment = createAsyncThunk('airports/requestedUpdatePayment', async (payment, { getState, rejectWithValue }) => {
    const response = await axios.put(`${ACCOUNT_URL}/${payment.accountId}/payments/${payment.paymentId}`, payment, {
        headers: {
            'authorization-token': getState().account.current.session.signInUserSession.idToken.jwtToken
        }
    });

    if (response.data.success) {
        return response.data.content;
    } else {
        throw rejectWithValue(response.data);
    }
});

export const requestedDeletePayment = createAsyncThunk('airports/requestedDeletePayment', async (payment, { getState, rejectWithValue }) => {
    const response = await axios.delete(`${ACCOUNT_URL}/${payment.accountId}/payments/${payment.paymentId}`, {
        headers: {
            'authorization-token': getState().account.current.session.signInUserSession.idToken.jwtToken
        }
    });

    if (response.data.success) {
        return response.data.content;
    } else {
        throw rejectWithValue(response.data);
    }
});

const accountSlice = createSlice({
    name: 'account',
    initialState,
    reducers: {
    },
    extraReducers: builder => {
        builder
            .addCase(requestedAccountInfo.pending, (state, action) => {
                state.status = 'loading';
                state.errors = undefined;
                state.current = { ...state.current, profile: {} };
            })
            .addCase(requestedAccountInfo.fulfilled, (state, action) => {
                state.status = 'loaded';
                state.current = { ...state.current, profile: action.payload };
            })
            .addCase(requestedAccountInfo.rejected, (state, action) => {
                state.status = 'error';
                state.errors = action.payload?.errors;
            })

            .addCase(requestedUpdateLan.pending, (state, action) => {
                state.status = 'loading';
                state.errors = undefined;
            })
            .addCase(requestedUpdateLan.fulfilled, (state, action) => {
                state.status = 'loaded';
                state.current = { ...state.current, lan: action.payload };
            })
            .addCase(requestedUpdateLan.rejected, (state, action) => {
                state.status = 'error';
                state.errors = action.payload?.errors;
            })

            .addCase(requestedUpdateCurrentAuthenticatedUser.pending, (state, action) => {
                //state.status = 'loading';
                //state.errors = undefined;
            })
            .addCase(requestedUpdateCurrentAuthenticatedUser.fulfilled, (state, action) => {
                //state.status = 'loaded';
                state.current = { ...state.current, session: action.payload };
            })
            .addCase(requestedUpdateCurrentAuthenticatedUser.rejected, (state, action) => {
                //state.status = 'error';
                //state.errors = action.payload?.errors;
            })

            .addCase(requestedMyAccountTransactions.pending, (state, action) => {
                state.status = 'loading';
                state.current = { ...state.current, confirmedTransactions: [] };
                state.errors = undefined;
            })
            .addCase(requestedMyAccountTransactions.fulfilled, (state, action) => {
                state.status = 'loaded';
                state.current = { ...state.current, confirmedTransactions: action.payload };
            })
            .addCase(requestedMyAccountTransactions.rejected, (state, action) => {
                state.status = 'error';
                state.errors = action.payload?.errors;
            })

            .addCase(requestedMyPendingTransactions.pending, (state, action) => {
                state.status = 'loading';
                state.current = { ...state.current, pendingTransactions: [] };
                state.errors = undefined;
            })
            .addCase(requestedMyPendingTransactions.fulfilled, (state, action) => {
                state.status = 'loaded';
                state.current = { ...state.current, pendingTransactions: action.payload };
            })
            .addCase(requestedMyPendingTransactions.rejected, (state, action) => {
                state.status = 'error';
                state.errors = action.payload?.errors;
            })

            .addCase(requestedAllConfirmedTransactions.pending, (state, action) => {
                state.status = 'loading';
                state.allConfirmedTransactions = [];
                state.errors = undefined;
            })
            .addCase(requestedAllConfirmedTransactions.fulfilled, (state, action) => {
                state.status = 'loaded';
                state.allConfirmedTransactions = action.payload;
            })
            .addCase(requestedAllConfirmedTransactions.rejected, (state, action) => {
                state.status = 'error';
                state.errors = action.payload?.errors;
            })

            .addCase(requestedAllPendingTransactions.pending, (state, action) => {
                state.status = 'loading';
                state.allPendingTransactions = [];
                state.errors = undefined;
            })
            .addCase(requestedAllPendingTransactions.fulfilled, (state, action) => {
                state.status = 'loaded';
                state.allPendingTransactions = action.payload;
            })
            .addCase(requestedAllPendingTransactions.rejected, (state, action) => {
                state.status = 'error';
                state.errors = action.payload?.errors;
            })

            .addCase(requestedCreatePayment.pending, (state, action) => {
                state.status = 'loading';
                state.errors = undefined;
            })
            .addCase(requestedCreatePayment.fulfilled, (state, action) => {
                state.status = 'submitted';
            })
            .addCase(requestedCreatePayment.rejected, (state, action) => {
                state.status = 'error';
                state.errors = action.payload?.errors;
            })

            .addCase(requestedUpdatePayment.pending, (state, action) => {
                state.status = 'loading';
                state.errors = undefined;
            })
            .addCase(requestedUpdatePayment.fulfilled, (state, action) => {
                state.status = 'submitted';
            })
            .addCase(requestedUpdatePayment.rejected, (state, action) => {
                state.status = 'error';
                state.errors = action.payload?.errors;
            })

            .addCase(requestedConfirmPayment.pending, (state, action) => {
                state.status = 'loading';
                state.errors = undefined;
            })
            .addCase(requestedConfirmPayment.fulfilled, (state, action) => {
                state.status = 'submitted';
            })
            .addCase(requestedConfirmPayment.rejected, (state, action) => {
                state.status = 'error';
                state.errors = action.payload?.errors;
            })

            .addCase(requestedDeletePayment.pending, (state, action) => {
                state.status = 'loading';
                state.errors = undefined;
            })
            .addCase(requestedDeletePayment.fulfilled, (state, action) => {
                state.status = 'deleted';
            })
            .addCase(requestedDeletePayment.rejected, (state, action) => {
                state.status = 'error';
                state.errors = action.payload?.errors;
            })
    }
});

// Selectors
export const { selectAll: selectAllAccounts, selectById: selectAccountById, selectIds: selectAccountsIds, selectTotal: selectTotalAccounts } =
    accountAdapter.getSelectors(state => state.account);

export const selectAccountStatus = (state) => state.account.status;
export const selectAccountErrors = (state) => state.account.errors;
export const selectAccountInfo = (state) => state.account.current;
export const selectIsAdmin = (state) => state.account.current.session && state.account.current.session.signInUserSession.idToken.payload['cognito:groups'] && state.account.current.session.signInUserSession.idToken.payload['cognito:groups'].includes('Admin');
export const selectAllPendingTransactions = (state) => state.account.allPendingTransactions || [];
export const selectAllConfirmedTransactions = (state) => state.account.allConfirmedTransactions || [];

export default accountSlice.reducer