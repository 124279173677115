import Header from "./Header";
import Footer from "./Footer";

function Layout(props) {
    return (
        <>
            <Header />
            <main className="d-flex justify-content-center">{props.children}</main>
            <Footer />
        </>
    )
}

export default Layout;