import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import AWS from 'aws-sdk';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import { useState, useEffect } from "react";
import { useIntl, FormattedMessage, FormattedDate, FormattedTime } from 'react-intl';
import { useSelector, useDispatch } from "react-redux";
import { Formik } from 'formik';
import * as yup from "yup";
import { v4 as uuidv4 } from 'uuid';
import { requestedCreateRoute, requestedUpdateRoute, selectRoutesStatus } from '../_redux/routesSlice';
import { requestedAirportsList, selectAllAirports } from '../_redux/airportsSlice';
import { requestedAirlinesList, selectAllAirlines } from '../_redux/airlinesSlice';
import './createEditRouteModal.css';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

const S3_BUCKET = 'frsolutions-travel-store';
const REGION = 'us-east-1';


AWS.config.update({
    accessKeyId: 'AKIA3WOC4JYP2IWSWBN2',
    secretAccessKey: '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'
})

const myBucket = new AWS.S3({
    params: { Bucket: S3_BUCKET },
    region: REGION,
})


function CreateEditRouteModal(props) {
    const intl = useIntl();

    const { isOpen, closeModal, closeModalRefresh, edit, duplicate, item } = props;
    const dispatch = useDispatch();

    const [itinerary, setItinerary] = useState({ airline: '', airlineName: '', departureAt: '', departureDate: '', departureTime: '', arrivalAt: '', arrivalDate: '', arrivalTime: '', arrivalAirport: '', departureAirport: '' });
    const [itineraryList, setItineraryList] = useState([]);

    const routeStatus = useSelector((state) => selectRoutesStatus(state));
    const allAirports = useSelector((state) => selectAllAirports(state));
    const allAirlines = useSelector((state) => selectAllAirlines(state));

    useEffect(() => {
        dispatch(requestedAirportsList());
        dispatch(requestedAirlinesList());

        if (item?.itinerary) {
            setItineraryList(item.itinerary);
        } else {
            setItineraryList([]);
        }
        console.log(edit, item);
    }, [props]);

    useEffect(() => {
        if (routeStatus === 'submitted') {
            closeModalRefresh();
        }
    }, [routeStatus]);

    const getFileS3Url = (fileName) => {
        const params = {
            Bucket: S3_BUCKET,
            Key: fileName
        };

        return myBucket.getSignedUrl('getObject', params);
    }

    const handleChangeAirline = function (event, newVal) {
        setItinerary({ ...itinerary, airline: newVal, airlineLogo: newVal.logo, airlineName: newVal.name });
    };

    const handleChangeArrivalAirport = (event) => {
        let airport = allAirports.find((item) => item.code === event.target.value);
        setItinerary({ ...itinerary, arrivalAirport: airport.code, arrivalAirportName: airport.name, arrivalCity: airport.city, arrivalCountry: airport.country });
    }

    const handleChangeDepartureAirport = (event) => {
        let airport = allAirports.find((item) => item.code === event.target.value);
        setItinerary({ ...itinerary, departureAirport: airport.code, departureAirportName: airport.name, departureCity: airport.city, departureCountry: airport.country });
    }

    const columns = [
        { field: 'airlineLogo', headerName: intl.formatMessage({ id: 'Labels.airline' }), width: 240, renderCell: (params) => (params.value && (<img height={"95%"} src={getFileS3Url(params.value)} />)) },
        {
            field: 'departureAt', headerName: intl.formatMessage({ id: 'Labels.departure' }), minWidth: 300, flex: 1, renderCell: (params) => (
                <div className="col-lg-3 d-flex align-items-center">
                    <div className="vr me-2"></div>
                    <div className=''>
                        <small className='text-secondary fw-bold fst-italic'><FormattedDate month='long' day='numeric' timeZone={"UTC"} value={new Date(params.row.departureAt * 1000)} />, <FormattedTime hour12={true} timeZone={"UTC"} value={new Date(params.row.departureAt * 1000)} /></small>
                        <br />
                        <small className='fw-bold'>{params.row.departureAirportName}, {params.row.departureCity}, {params.row.departureCountry} ({params.row.departureAirport})</small>
                    </div>
                </div>
            )
        },
        {
            field: 'arrivalAt', type: "time", editable: true, headerName: intl.formatMessage({ id: 'Labels.arrival' }), minWidth: 300, flex: 1, renderCell: (params) => (
                <div className="col-lg-3 d-flex align-items-center">
                    <div className="vr me-2"></div>
                    <div className=''>
                        <small className='text-secondary fw-bold fst-italic'><FormattedDate month='long' day='numeric' timeZone={"UTC"} value={new Date(params.row.arrivalAt * 1000)} />, <FormattedTime hour12={true} timeZone={"UTC"} value={new Date(params.row.arrivalAt * 1000)} /></small>
                        <br />
                        <small className='fw-bold'>{params.row.arrivalAirportName}, {params.row.arrivalCity}, {params.row.arrivalCountry} ({params.row.arrivalAirport})</small>
                    </div>
                </div>
            )
        },
        {
            field: 'actions',
            type: 'actions',
            width: 100,
            getActions: (params) => [
                <GridActionsCellItem style={{ color: '#2987cf' }} icon={<EditIcon onClick={() => {
                    setItinerary(params.row);
                }} />} label="Edit" />,
                <GridActionsCellItem style={{ color: 'red' }} icon={<DeleteIcon onClick={() => {
                    setItineraryList(itineraryList.filter(item => item.id !== params.row.id));
                }
                } />} label="Delete" />,
            ],
        }
    ];

    const schema = yup.object().shape({
        name: yup.string().required(),
        price: yup.number().required().min(0).max(100000),
        autoconfirm: yup.boolean(),
        /*departureAirport: yup.string().required(),
        arrivalAirport: yup.string().required(),
        departureDate: yup.date().required(),
        departureTime: yup.string().required(),
        arrivalDate: yup.date().required(),
        arrivalTime: yup.string().required(),*/
        status: yup.string().required(),
        firstClass: yup.number().required().min(0).max(1000),
        businessClass: yup.number().required().min(0).max(1000),
        premiumClass: yup.number().required().min(0).max(1000),
        economyClass: yup.number().required().min(0).max(1000)
    });

    const handleSubmitForm = (values, actions) => {
        let departureDateTime = values.departureDate + ' ' + values.departureTime;
        let departureUtcDateTime = new Date(departureDateTime);
        let departureAt = new Date(Date.UTC(departureUtcDateTime.getFullYear(), departureUtcDateTime.getMonth(), departureUtcDateTime.getDate(), departureUtcDateTime.getHours(), departureUtcDateTime.getMinutes())).getTime() / 1000;

        let arrivalDateTime = values.arrivalDate + ' ' + values.arrivalTime;
        let arrivalUtcDateTime = new Date(arrivalDateTime);
        let arrivalAt = new Date(Date.UTC(arrivalUtcDateTime.getFullYear(), arrivalUtcDateTime.getMonth(), arrivalUtcDateTime.getDate(), arrivalUtcDateTime.getHours(), arrivalUtcDateTime.getMinutes())).getTime() / 1000;

        if (edit) {
            dispatch(requestedUpdateRoute({ ...values, itinerary: itineraryList, arrivalAt: itineraryList[itineraryList.length - 1].arrivalAt, departureAt: itineraryList[0].departureAt, arrivalAirport: itineraryList[itineraryList.length - 1].arrivalAirport, departureAirport: itineraryList[0].departureAirport }));
        } else {
            let id = 'route_' + uuidv4();
            dispatch(requestedCreateRoute({ ...values, itinerary: itineraryList, arrivalAt: itineraryList[itineraryList.length - 1].arrivalAt, departureAt: itineraryList[0].departureAt, arrivalAirport: itineraryList[itineraryList.length - 1].arrivalAirport, departureAirport: itineraryList[0].departureAirport, routeId: id }));
        }
        actions.setSubmitting(false);
    };

    return (
        <Formik
            validationSchema={schema}
            onSubmit={handleSubmitForm}
            initialValues={edit || duplicate ? {...item, autoconfirm: !!item.autoconfirm} : {
                name: '',
                price: 0,
                status: 'OPEN',
                firstClass: 0,
                businessClass: 0,
                premiumClass: 0,
                economyClass: 0,
                autoconfirm: false
            }}
        >
            {({
                handleSubmit,
                handleChange,
                handleBlur,
                isSubmitting,
                values,
                touched,
                isValid,
                errors,
            }) => (
                <Modal show={isOpen} onHide={closeModal} dialogClassName="" fullscreen={true}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <FormattedMessage id={edit ? "Travel.edit_route" : "Travel.new_route"} />
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form noValidate onSubmit={handleSubmit}>
                            <Row className="mb-2">
                                <Form.Group as={Col} md="6">
                                    <Form.Label>
                                        <FormattedMessage id="Labels.name" />
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="name"
                                        onChange={handleChange}
                                        value={values.name}
                                        isValid={touched.name && !errors.name}
                                        isInvalid={!!errors.name}
                                    />
                                    <Form.Control.Feedback />
                                    <Form.Control.Feedback type="invalid" />
                                </Form.Group>
                                <Form.Group as={Col} md="3">
                                    <Form.Label>
                                        <FormattedMessage id="Labels.price" />
                                    </Form.Label>
                                    <Form.Control
                                        type="number"
                                        name="price"
                                        onChange={handleChange}
                                        value={values.price}
                                        isValid={touched.price && !errors.price}
                                        isInvalid={!!errors.price}
                                    />
                                    <Form.Control.Feedback />
                                    <Form.Control.Feedback type="invalid" />
                                </Form.Group>
                                <Form.Group as={Col} md="2">
                                    <Form.Label>
                                        <FormattedMessage id="Labels.status" />
                                    </Form.Label>
                                    <Form.Select
                                        name='status'
                                        onChange={handleChange}
                                        value={values.status}
                                        isValid={touched.status && !errors.status}
                                        isInvalid={!!errors.status}
                                    >
                                        <option value="OPEN"><FormattedMessage id="Travel.onsale" /></option>
                                        <option value="SOLD_OUT"><FormattedMessage id="Travel.soldout" /></option>
                                    </Form.Select>
                                    <Form.Control.Feedback />
                                    <Form.Control.Feedback type="invalid" />
                                </Form.Group>
                                <Form.Group as={Col} md="1">
                                    <Form.Label>
                                        <FormattedMessage id="Labels.autoconfirm" />
                                    </Form.Label>
                                    <Form.Switch
                                        name='autoconfirm'
                                        onChange={handleChange}
                                        checked={values.autoconfirm}
                                        isValid={touched.autoconfirm && !errors.autoconfirm}
                                        isInvalid={!!errors.autoconfirm}
                                    >
                                    </Form.Switch>
                                    <Form.Control.Feedback />
                                    <Form.Control.Feedback type="invalid" />
                                </Form.Group>
                            </Row>
                            {/*<Row className="mb-1">
                                <Form.Group as={Col} md="6" className='mb-2'>
                                    <Row>
                                        <Form.Group as={Col} md="12" className='mb-1'>
                                            <Form.Label>
                                                <FormattedMessage id="Labels.departure" />
                                            </Form.Label>
                                            <Form.Select
                                                name='departureAirport'
                                                onChange={handleChange}
                                                value={values.departureAirport}
                                                isValid={touched.departureAirport && !errors.departureAirport}
                                                isInvalid={!!errors.departureAirport}
                                            >
                                                <option value=''>Select</option>
                                                {allAirports.map((airport) => {
                                                    return (<option value={airport.code}>{airport.code} - {airport.name} | {airport.city}, {airport.country}</option>);
                                                })}
                                            </Form.Select>
                                            <Form.Control.Feedback />
                                            <Form.Control.Feedback type="invalid" />
                                        </Form.Group>
                                        <Form.Group as={Col} md="6" className='mb-1'>
                                            <Form.Control
                                                type="date"
                                                name="departureDate"
                                                onChange={handleChange}
                                                value={values.departureDate}
                                                isValid={touched.departureDate && !errors.departureDate}
                                                isInvalid={!!errors.departureDate}
                                            />
                                            <Form.Control.Feedback />
                                            <Form.Control.Feedback type="invalid" />
                                        </Form.Group>
                                        <Form.Group as={Col} md="6">
                                            <Form.Control
                                                type="time"
                                                name="departureTime"
                                                onChange={handleChange}
                                                value={values.departureTime}
                                                isValid={touched.departureTime && !errors.departureTime}
                                                isInvalid={!!errors.departureTime}
                                            />
                                            <Form.Control.Feedback />
                                            <Form.Control.Feedback type="invalid" />
                                        </Form.Group>
                                    </Row>
                                </Form.Group>
                                <Form.Group as={Col} md="6" className='mb-2'>
                                    <Row>
                                        <Form.Group as={Col} md="12" className='mb-1'>
                                            <Form.Label>
                                                <FormattedMessage id="Labels.arrival" />
                                            </Form.Label>
                                            <Form.Select
                                                name='arrivalAirport'
                                                onChange={handleChange}
                                                value={values.arrivalAirport}
                                                isValid={touched.arrivalAirport && !errors.arrivalAirport}
                                                isInvalid={!!errors.arrivalAirport}
                                            >
                                                <option value=''>Select</option>
                                                {allAirports.map((airport) => {
                                                    return (<option value={airport.code}>{airport.code} - {airport.name} | {airport.city}, {airport.country}</option>);
                                                })}
                                            </Form.Select>
                                            <Form.Control.Feedback />
                                            <Form.Control.Feedback type="invalid" />
                                        </Form.Group>
                                        <Form.Group as={Col} md="6" className='mb-1'>
                                            <Form.Control
                                                type="date"
                                                name="arrivalDate"
                                                onChange={handleChange}
                                                value={values.arrivalDate}
                                                isValid={touched.arrivalDate && !errors.arrivalDate}
                                                isInvalid={!!errors.arrivalDate}
                                            />
                                            <Form.Control.Feedback />
                                            <Form.Control.Feedback type="invalid" />
                                        </Form.Group>
                                        <Form.Group as={Col} md="6">
                                            <Form.Control
                                                type="time"
                                                name="arrivalTime"
                                                onChange={handleChange}
                                                value={values.arrivalTime}
                                                isValid={touched.arrivalTime && !errors.arrivalTime}
                                                isInvalid={!!errors.arrivalTime}
                                            />
                                            <Form.Control.Feedback />
                                            <Form.Control.Feedback type="invalid" />
                                        </Form.Group>
                                    </Row>
                                </Form.Group>
                            </Row>*/}
                            <Row className="mb-3">
                                <Form.Group as={Col} md="12" className='mb-1'>
                                    <Form.Label>
                                        <FormattedMessage id="Labels.seats" />: <FormattedMessage id="Labels.availability" />
                                    </Form.Label>
                                </Form.Group>
                                <Form.Group as={Col} md="3" className='mb-1'>
                                    <Form.Label>
                                        <FormattedMessage id="Travel.firstClass" />
                                    </Form.Label>
                                    <Form.Control
                                        type="number"
                                        name='firstClass'
                                        onChange={handleChange}
                                        value={values.firstClass}
                                        isValid={touched.firstClass && !errors.firstClass}
                                        isInvalid={!!errors.firstClass}
                                    />
                                    <Form.Control.Feedback />
                                    <Form.Control.Feedback type="invalid" />
                                </Form.Group>
                                <Form.Group as={Col} md="3" className='mb-1'>
                                    <Form.Label>
                                        <FormattedMessage id="Travel.businessClass" />
                                    </Form.Label>
                                    <Form.Control
                                        type="number"
                                        name='businessClass'
                                        onChange={handleChange}
                                        value={values.businessClass}
                                        isValid={touched.businessClass && !errors.businessClass}
                                        isInvalid={!!errors.businessClass}
                                    />
                                    <Form.Control.Feedback />
                                    <Form.Control.Feedback type="invalid" />
                                </Form.Group>
                                <Form.Group as={Col} md="3" className='mb-1'>
                                    <Form.Label>
                                        <FormattedMessage id="Travel.premiumClass" />
                                    </Form.Label>
                                    <Form.Control
                                        type="number"
                                        name='premiumClass'
                                        onChange={handleChange}
                                        value={values.premiumClass}
                                        isValid={touched.premiumClass && !errors.premiumClass}
                                        isInvalid={!!errors.premiumClass}
                                    />
                                    <Form.Control.Feedback />
                                    <Form.Control.Feedback type="invalid" />
                                </Form.Group>
                                <Form.Group as={Col} md="3" className='mb-1'>
                                    <Form.Label>
                                        <FormattedMessage id="Travel.economyClass" />
                                    </Form.Label>
                                    <Form.Control
                                        type="number"
                                        name='economyClass'
                                        onChange={handleChange}
                                        value={values.economyClass}
                                        isValid={touched.economyClass && !errors.economyClass}
                                        isInvalid={!!errors.economyClass}
                                    />
                                    <Form.Control.Feedback />
                                    <Form.Control.Feedback type="invalid" />
                                </Form.Group>
                            </Row>
                        </Form>
                        <hr className="fw-bold hr-text" data-content="ITINERARY"></hr>
                        <Row className='p-3'>
                            <div className='col-md-4 border rounded-1 p-2'>
                                <Row className="mb-2">
                                    <Form.Group as={Col} md="8">
                                        <Form.Label>
                                            <FormattedMessage id="Labels.airline" />
                                        </Form.Label>
                                        <Autocomplete
                                            //sx={{ width: 300 }}
                                            options={allAirlines}
                                            required
                                            autoHighlight
                                            value={itinerary.airline}
                                            onChange={handleChangeAirline}
                                            onReset={() => {

                                            }}
                                            inputValue={itinerary.airlineName}
                                            onInputChange={(event) => {
                                                if (event && event.target) {
                                                    setItinerary({ ...itinerary, airlineName: event.target.value });
                                                }
                                            }}
                                            getOptionLabel={(option) => option?.name ? option.name : ''}
                                            renderOption={(props, option) => (
                                                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                    <img
                                                        loading="lazy"
                                                        width="20"
                                                        src={getFileS3Url(option.logo)}
                                                        //srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                                        alt=""
                                                    />
                                                    {option.name}
                                                </Box>
                                            )}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    size="small"
                                                    inputProps={{
                                                        ...params.inputProps,
                                                        autoComplete: 'new-password', // disable autocomplete and autofill
                                                    }}
                                                />
                                            )}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} md="4">
                                        <Form.Label style={{ color: 'transparent' }}>
                                            {'.'}
                                        </Form.Label>
                                        <br />
                                        {itinerary.airlineLogo && <img loading="lazy" style={{ height: 'auto', maxWidth: '100%' }} src={getFileS3Url(itinerary.airlineLogo)} alt="" />}
                                    </Form.Group>
                                </Row>
                                <Row>
                                    <Form.Group as={Col} md="12" className='mb-1'>
                                        <Form.Label>
                                            <FormattedMessage id="Labels.departure" />
                                        </Form.Label>
                                        <Form.Select
                                            name='itineraryDepartureAirport'
                                            required
                                            onChange={handleChangeDepartureAirport}
                                            value={itinerary.departureAirport}
                                            isValid={touched.itineraryDepartureAirport && !errors.itineraryDepartureAirport}
                                            isInvalid={!!errors.itineraryDepartureAirport}
                                        >
                                            <option value=''>Select</option>
                                            {allAirports.map((airport) => {
                                                return (<option value={airport.code}>{airport.code} - {airport.name} | {airport.city}, {airport.country}</option>);
                                            })}
                                        </Form.Select>
                                        <Form.Control.Feedback />
                                        <Form.Control.Feedback type="invalid" />
                                    </Form.Group>
                                    <Form.Group as={Col} md="6" className='mb-1'>
                                        <Form.Control
                                            type="date"
                                            required
                                            name="itineraryDepartureDate"
                                            onChange={(event) => {
                                                let departureDate = event.target.value;
                                                let departureAt = undefined;
                                                if (departureDate && itinerary.departureTime) {
                                                    let departureDateTime = departureDate + ' ' + itinerary.departureTime;
                                                    let utcDateTime = new Date(departureDateTime);
                                                    departureAt = new Date(Date.UTC(utcDateTime.getFullYear(), utcDateTime.getMonth(), utcDateTime.getDate(), utcDateTime.getHours(), utcDateTime.getMinutes())).getTime() / 1000;
                                                }
                                                setItinerary({ ...itinerary, departureDate: departureDate, departureAt: departureAt });
                                            }}
                                            value={itinerary.departureDate}
                                            isValid={touched.itineraryDepartureDate && !errors.itineraryDepartureDate}
                                            isInvalid={!!errors.itineraryDepartureDate}
                                        />
                                        <Form.Control.Feedback />
                                        <Form.Control.Feedback type="invalid" />
                                    </Form.Group>
                                    <Form.Group as={Col} md="6">
                                        <Form.Control
                                            type="time"
                                            required
                                            name="itineraryDepartureTime"
                                            onChange={(event) => {
                                                let departureTime = event.target.value;
                                                let departureAt = undefined;
                                                if (itinerary.departureDate && departureTime) {
                                                    let departureDateTime = itinerary.departureDate + ' ' + departureTime;
                                                    let utcDateTime = new Date(departureDateTime);
                                                    departureAt = new Date(Date.UTC(utcDateTime.getFullYear(), utcDateTime.getMonth(), utcDateTime.getDate(), utcDateTime.getHours(), utcDateTime.getMinutes())).getTime() / 1000;
                                                }
                                                setItinerary({ ...itinerary, departureTime: departureTime, departureAt: departureAt });
                                            }}
                                            value={itinerary.departureTime}
                                            isValid={touched.itineraryDepartureTime && !errors.itineraryDepartureTime}
                                            isInvalid={!!errors.itineraryDepartureTime}
                                        />
                                        <Form.Control.Feedback />
                                        <Form.Control.Feedback type="invalid" />
                                    </Form.Group>
                                </Row>
                                <Row>
                                    <Form.Group as={Col} md="12" className='mb-1'>
                                        <Form.Label>
                                            <FormattedMessage id="Labels.arrival" />
                                        </Form.Label>
                                        <Form.Select
                                            name='itineraryArrivalAirport'
                                            required
                                            onChange={handleChangeArrivalAirport}
                                            value={itinerary.arrivalAirport}
                                            isValid={touched.itineraryArrivalAirport && !errors.itineraryArrivalAirport}
                                            isInvalid={!!errors.itineraryArrivalAirport}
                                        >
                                            <option value=''>Select</option>
                                            {allAirports.map((airport) => {
                                                return (<option value={airport.code}>{airport.code} - {airport.name} | {airport.city}, {airport.country}</option>);
                                            })}
                                        </Form.Select>
                                        <Form.Control.Feedback />
                                        <Form.Control.Feedback type="invalid" />
                                    </Form.Group>
                                    <Form.Group as={Col} md="6" className='mb-1'>
                                        <Form.Control
                                            type="date"
                                            required
                                            name="itineraryArrivalDate"
                                            onChange={(event) => {
                                                let arrivalDate = event.target.value;
                                                let arrivalAt = undefined;
                                                if (arrivalDate && itinerary.arrivalTime) {
                                                    let arrivalDateTime = arrivalDate + ' ' + itinerary.arrivalTime;
                                                    let utcDateTime = new Date(arrivalDateTime);
                                                    arrivalAt = new Date(Date.UTC(utcDateTime.getFullYear(), utcDateTime.getMonth(), utcDateTime.getDate(), utcDateTime.getHours(), utcDateTime.getMinutes())).getTime() / 1000;
                                                }
                                                setItinerary({ ...itinerary, arrivalDate: arrivalDate, arrivalAt: arrivalAt });
                                            }}
                                            value={itinerary.arrivalDate}
                                            isValid={touched.itineraryArrivalDate && !errors.itineraryArrivalDate}
                                            isInvalid={!!errors.itineraryArrivalDate}
                                        />
                                        <Form.Control.Feedback />
                                        <Form.Control.Feedback type="invalid" />
                                    </Form.Group>
                                    <Form.Group as={Col} md="6">
                                        <Form.Control
                                            type="time"
                                            required
                                            name="itineraryArrivalTime"
                                            onChange={(event) => {
                                                let arrivalTime = event.target.value;
                                                let arrivalAt = undefined;
                                                if (itinerary.arrivalDate && arrivalTime) {
                                                    let arrivalDateTime = itinerary.arrivalDate + ' ' + arrivalTime;
                                                    let utcDateTime = new Date(arrivalDateTime);
                                                    arrivalAt = new Date(Date.UTC(utcDateTime.getFullYear(), utcDateTime.getMonth(), utcDateTime.getDate(), utcDateTime.getHours(), utcDateTime.getMinutes())).getTime() / 1000;
                                                }
                                                setItinerary({ ...itinerary, arrivalTime: arrivalTime, arrivalAt: arrivalAt });
                                            }}
                                            value={itinerary.arrivalTime}
                                            isValid={touched.itineraryArrivalTime && !errors.itineraryArrivalTime}
                                            isInvalid={!!errors.itineraryArrivalTime}
                                        />
                                        <Form.Control.Feedback />
                                        <Form.Control.Feedback type="invalid" />
                                    </Form.Group>
                                </Row>
                                <div className='d-flex flex-row-reverse'>
                                    <Button variant="primary" onClick={() => {
                                        if (itinerary.id) {
                                            //setItineraryList(itineraryList.filter(item => item.id !== itinerary.id));
                                            setItineraryList(itineraryList.map(item => {
                                                if (item.id === itinerary.id) {
                                                    return { ...item, ...itinerary };
                                                } else {
                                                    return item;
                                                }
                                            }));
                                        } else {
                                            setItineraryList([...itineraryList, { ...itinerary, id: uuidv4() }]);
                                        }

                                        setItinerary({ id: undefined, airline: '', airlineName: '', departureAt: '', departureDate: '', departureTime: '', arrivalAt: '', arrivalDate: '', arrivalTime: '', arrivalAirport: '', departureAirport: '' });
                                    }} disabled={!itinerary || !itinerary.airlineName || !itinerary.departureAt || !itinerary.arrivalAt || !itinerary.departureAirport || !itinerary.arrivalAirport}>
                                        <FormattedMessage id={itinerary.id ? "Actions.save" : "Actions.add"} />
                                    </Button>
                                    <Button className='mx-1' variant="secondary" onClick={() => {
                                        setItinerary({ id: undefined, airline: '', airlineName: '', departureAt: '', departureDate: '', departureTime: '', arrivalAt: '', arrivalDate: '', arrivalTime: '', arrivalAirport: '', departureAirport: '' });
                                    }}>
                                        <FormattedMessage id="Actions.reset" />
                                    </Button>
                                </div>
                            </div>
                            <div className='col-md-8'>
                                <div style={{ height: 370, width: '100%' }}>
                                    <DataGrid
                                        rows={itineraryList}
                                        columns={columns}
                                        //pageSize={50}
                                        rowsPerPageOptions={[5, 10, 50, 100, 500, 1000]}
                                    />
                                </div>
                            </div>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={closeModal}>
                            <FormattedMessage id="Actions.close" />
                        </Button>
                        <Button variant="primary" disabled={!isValid || isSubmitting || !itineraryList?.length} onClick={handleSubmit}>
                            {(isSubmitting || routeStatus === 'loading') && (
                                <Spinner
                                    as="span"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    animation="border"
                                />
                            )}
                            <FormattedMessage id="Actions.save" />
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}
        </Formik>
    );
}

export default CreateEditRouteModal;