import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useState, useEffect } from "react";
import { FormattedMessage, FormattedNumber } from 'react-intl';
import Avatar from '@mui/material/Avatar';
import { useSelector, useDispatch } from "react-redux";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Formik } from 'formik';
import * as yup from "yup";
import { v4 as uuidv4 } from 'uuid';
import { selectAccountInfo, selectAccountStatus, requestedCreatePayment, requestedUpdatePayment } from '../_redux/accountSlice';

function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
}

function stringAvatar(name) {
    return {
        sx: {
            bgcolor: stringToColor(name),
        },
        //children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
}

function CreateEditZellePaymentModal(props) {
    const { isOpen, closeModal, closeModalRefresh, edit, item, isAdmin, user } = props;
    const dispatch = useDispatch();

    const accountInfo = useSelector((state) => selectAccountInfo(state));
    const accountStatus = useSelector((state) => selectAccountStatus(state));


    useEffect(() => {
        if (accountStatus === 'submitted') {
            closeModalRefresh();
        }
    }, [accountStatus]);

    const schema = yup.object().shape({
        paymentType: yup.string().required(),
        sender: isAdmin ? yup.string() : yup.string().required(),
        amount: yup.number().required().min(isAdmin ? -1000000 : 1),
        transactionNumber: isAdmin ? yup.string() : yup.string().required(),
        note: yup.string(),
    });

    const handleSubmitForm = (values, actions) => {
        if (edit) {
            dispatch(requestedUpdatePayment({ ...item, ...values }));
        } else {
            dispatch(requestedCreatePayment({ ...values, userId: isAdmin ? user?.id : undefined, paymentId: `pay_${uuidv4()}`, status: 'Pending' }));
        }
        actions.setSubmitting(false);
    };

    return (
        <Formik
            validationSchema={schema}
            onSubmit={handleSubmitForm}
            initialValues={edit ? item : {
                sender: '',
                amount: 0,
                transactionNumber: '',
                note: '',
                paymentType: isAdmin ? 'Balance Adjustment' : 'ZELLE'
            }}
        >
            {({
                handleSubmit,
                handleChange,
                handleBlur,
                isSubmitting,
                values,
                touched,
                isValid,
                errors,
            }) => (
                <Modal show={isOpen} onHide={closeModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <FormattedMessage id={edit ? "Labels.edit_payment" : (isAdmin ? "Labels.new_transaction" : "Labels.new_payment")} />
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {isAdmin && (
                            <div className='row border rounded-3 p-1 mx-1'>
                                <div className='d-flex col-10 align-items-center'>
                                    <Avatar {...stringAvatar(user.name)} >
                                        <AccountCircleIcon />
                                    </Avatar>
                                    <span className="m-2 fw-bold text-wrap" style={{ color: '#005198' }}>{user.name}</span>
                                </div>
                                <div className='col-2 justify-content-end d-flex align-items-center'>
                                    <span className="fw-bold text-wrap badge rounded-pill bg-warning text-dark p-3"><FormattedNumber value={user.credit || 0} style="currency" currency="USD" /></span>
                                </div>
                            </div>
                        )}
                        <Form noValidate onSubmit={handleSubmit}>
                            <Form.Group as={Col} md="12">
                                <Form.Label>
                                    <FormattedMessage id="Labels.type" />
                                </Form.Label>
                                <Form.Select
                                    name='paymentType'
                                    onChange={handleChange}
                                    value={values.paymentType}
                                    isValid={touched.paymentType && !errors.paymentType}
                                    isInvalid={!!errors.paymentType}
                                >
                                    {isAdmin && (
                                        <option value="Balance Adjustment">Balance Adjustment</option>
                                    )}
                                    <option value="ZELLE">ZELLE</option>
                                    <option value="Deposit">Deposit</option>
                                </Form.Select>
                                <Form.Control.Feedback />
                                <Form.Control.Feedback type="invalid" />
                            </Form.Group>
                            {values.paymentType !== 'Balance Adjustment' && (
                                <Form.Group as={Col} md="12">
                                    <Form.Label>
                                        <FormattedMessage id="Labels.sender" />
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="sender"
                                        onChange={handleChange}
                                        value={values.sender}
                                        isValid={touched.sender && !errors.sender}
                                        isInvalid={!!errors.sender}
                                    />
                                    <Form.Control.Feedback />
                                    <Form.Control.Feedback type="invalid" />
                                </Form.Group>
                            )}

                            <Form.Group as={Col} md="12">
                                <Form.Label>
                                    <FormattedMessage id="Labels.amount" />
                                </Form.Label>
                                <Form.Control
                                    type="number"
                                    name="amount"
                                    onChange={handleChange}
                                    value={values.amount}
                                    isValid={touched.amount && !errors.amount}
                                    isInvalid={!!errors.amount}
                                />
                                <Form.Control.Feedback />
                                <Form.Control.Feedback type="invalid" />
                            </Form.Group>
                            <Row className="mb-3">
                                {values.paymentType !== 'Balance Adjustment' && (
                                    <Form.Group as={Col} md="12">
                                        <Form.Label>
                                            <FormattedMessage id="Labels.transaction" /> #
                                        </Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="transactionNumber"
                                            onChange={handleChange}
                                            value={values.transactionNumber}
                                            isValid={touched.transactionNumber && !errors.transactionNumber}
                                            isInvalid={!!errors.transactionNumber}
                                        />
                                        <Form.Control.Feedback />
                                        <Form.Control.Feedback type="invalid" />
                                    </Form.Group>
                                )}
                                <Form.Group as={Col} md="12">
                                    <Form.Label>
                                        <FormattedMessage id="Labels.note" />
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="note"
                                        onChange={handleChange}
                                        value={values.note}
                                        isValid={touched.note && !errors.note}
                                        isInvalid={!!errors.note}
                                    />
                                    <Form.Control.Feedback />
                                    <Form.Control.Feedback type="invalid" />
                                </Form.Group>
                            </Row>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={closeModal}>
                            <FormattedMessage id="Actions.close" />
                        </Button>
                        <Button variant="primary" disabled={!isValid || isSubmitting || accountStatus === 'loading'} onClick={handleSubmit}>
                            {(isSubmitting || accountStatus === 'loading') && (
                                <Spinner
                                    as="span"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    animation="border"
                                />
                            )}
                            <FormattedMessage id="Actions.save" />
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}
        </Formik>
    );
}

export default CreateEditZellePaymentModal;