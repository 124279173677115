
function Footer(props) {
  const today = new Date().getFullYear();

  return (
    <div className="footer">
      <div
        className={`d-flex flex-column flex-md-row align-items-center justify-content-center`}
      >
        <div className="text-muted fw-bolder order-2 order-md-1">
          <span className="text-muted fw-bold mr-2">{today.toString()}</span> &copy;{" "}
          <a
            href="https://www.internationalvuelos.com"
            target="_blank"
            rel="noopener noreferrer"
            className="text-decoration-none text-secondary text-hover-primary"
          >
            International Service LLC
          </a>
          <span className="fw-bold"> &#38; </span>
          <a
            href="https://www.smartechnologieslab.com"
            target="_blank"
            rel="noopener noreferrer"
            className="text-decoration-none text-secondary text-hover-primary"
          >
            Smart Technologies Lab
          </a>
        </div>
      </div>
    </div>
  )
}

export default Footer;