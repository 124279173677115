import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { useState, useEffect } from "react";
import { FormattedMessage } from 'react-intl';
import { useSelector, useDispatch } from "react-redux";
import { Formik } from 'formik';
import * as yup from "yup";
import AWS from 'aws-sdk';
import { v4 as uuidv4 } from 'uuid';
import { requestedCreateAirline, requestedUpdateAirline, selectAirlinesStatus } from '../_redux/airlinesSlice';
import { width } from '@mui/system';

const S3_BUCKET = 'frsolutions-travel-store';
const REGION = 'us-east-1';


AWS.config.update({
    accessKeyId: 'AKIA3WOC4JYP2IWSWBN2',
    secretAccessKey: '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'
})

const myBucket = new AWS.S3({
    params: { Bucket: S3_BUCKET },
    region: REGION,
})


function CreateEditAirlineModal(props) {
    const { isOpen, closeModal, closeModalRefresh, edit, item } = props;
    const dispatch = useDispatch();

    const airlineStatus = useSelector((state) => selectAirlinesStatus(state));
    const [selectedFile, setSelectedFile] = useState(undefined);
    const [readFile, setReadFile] = useState(undefined);

    useEffect(() => {
        if (edit && item.logo) {
            getFileS3Url(item.logo);
        }
    }, [props]);

    useEffect(() => {
        if (airlineStatus === 'submitted') {
            closeModalRefresh();
        }
    }, [airlineStatus]);

    const schema = yup.object().shape({
        name: yup.string().required(),
    });

    const handleSubmitForm = (values, actions) => {
        if (selectedFile) {
            const fileName = `public/travel/${uuidv4()}.jpg`;
            uploadFileS3(selectedFile, fileName);
            values = {...values, logo: fileName}
            console.log('111');
        }
        if (edit) {
            dispatch(requestedUpdateAirline(values));
        } else {
            dispatch(requestedCreateAirline(values));
        }
        actions.setSubmitting(false);
    };

    const uploadFileS3 = (file, fileName) => {
        const params = {
            Body: file,
            Bucket: S3_BUCKET,
            Key: fileName
        };

        myBucket.putObject(params)
            .on('httpUploadProgress', (evt) => {
                if (evt.loaded === evt.total) {
                    console.log("submitted foto");
                }
                //setProgress(Math.round((evt.loaded / evt.total) * 100))
            })
            .send((err) => {
                if (err) console.log(err)
            })
    }

    const getFileS3Url = (fileName) => {
        const params = {
            Bucket: S3_BUCKET,
            Key: fileName
        };

        myBucket.getSignedUrl('getObject', params, (err, url) => {
            setReadFile(url);
        });
    }

    const handleFileInput = (e) => {
        setSelectedFile(e.target.files[0]);
    }


    return (
        <Formik
            validationSchema={schema}
            onSubmit={handleSubmitForm}
            initialValues={edit ? item : {
                name: '',
            }}
        >
            {({
                handleSubmit,
                handleChange,
                handleBlur,
                isSubmitting,
                values,
                touched,
                isValid,
                errors,
            }) => (
                <Modal show={isOpen} onHide={closeModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <FormattedMessage id={edit ? "Travel.edit_airline" : "Travel.new_airline"} />
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form noValidate onSubmit={handleSubmit}>
                            <Form.Group className="mb-3">
                                <Form.Label>
                                    <FormattedMessage id="Labels.name" />
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    name="name"
                                    onChange={handleChange}
                                    value={values.name}
                                    isValid={touched.name && !errors.name}
                                    isInvalid={!!errors.name}
                                />
                                <Form.Control.Feedback />
                                <Form.Control.Feedback type="invalid" />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>
                                    <FormattedMessage id="Labels.logo" />
                                </Form.Label>
                                <Form.Control
                                    type="file"
                                    name="logo"
                                    onChange={handleFileInput}
                                    //value={selectedFile}
                                    isValid={touched.logo && !errors.logo}
                                    isInvalid={!!errors.logo}
                                />
                                <Form.Control.Feedback />
                                <Form.Control.Feedback type="invalid" />
                                {selectedFile && (
                                    <img height={100} src={URL.createObjectURL(selectedFile)} />
                                )}
                                {readFile && !selectedFile && (
                                    <img height={100} src={readFile} />
                                )}
                            </Form.Group>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={closeModal}>
                            <FormattedMessage id="Actions.close" />
                        </Button>
                        <Button variant="primary" disabled={!isValid || isSubmitting} onClick={handleSubmit}>
                            {(isSubmitting || airlineStatus === 'loading') && (
                                <Spinner
                                    as="span"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    animation="border"
                                />
                            )}
                            <FormattedMessage id="Actions.save" />
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}
        </Formik>
    );
}

export default CreateEditAirlineModal;