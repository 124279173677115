import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import { useState, useEffect } from "react";
import { FormattedMessage, FormattedDate, FormattedTime, FormattedNumber } from 'react-intl';
import Avatar from '@mui/material/Avatar';
import PaidIcon from '@mui/icons-material/Paid';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import { useSelector, useDispatch } from "react-redux";
import { selectAccountInfo } from '../_redux/accountSlice';
import ConfirmPaymentModal from './ConfirmPaymentModal';
import CreateEditZellePaymentModal from './CreateEditZellePaymentModal';
import DeletePayment from './DeletePayment';

function PendingTransactionItemCard(props) {
    const { transaction, isAdmin, closeModalRefresh } = props;

    const accountInfo = useSelector((state) => selectAccountInfo(state));
    const [showConfirm, setShowConfirm] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [showDelete, setShowDelete] = useState(false);

    const handleCloseModals = () => {
        setShowConfirm(false);
        setShowEdit(false);
        setShowDelete(false);
    };

    const handleCloseRefresh = () => {
        handleCloseModals();
        closeModalRefresh();
    };

    return (
        <>
            <div className='row border rounded-3 p-1 mx-1'>
                <div className='d-flex col-md-4 align-items-center'>
                    <Avatar sx={{ bgcolor: 'green' }}>
                        <PaidIcon />
                    </Avatar>
                    <div className='d-flex flex-column mx-2'>
                        <span className="fw-bold" style={{ color: '#005198' }}>
                            <small>{transaction.paymentType}</small>{isAdmin && (
                                <small> | {transaction.userFullName}</small>
                            )}
                        </span>
                        <span className="text-secondary fw-bold fst-italic">
                            <small><FormattedDate month='long' day='numeric' year='numeric' value={new Date(transaction.createdAt * 1000)} /></small>
                            <small>, <FormattedTime hour12={true} value={new Date(transaction.createdAt * 1000)} /></small>
                        </span>
                    </div>

                </div>
                <div className='col-md-4 my-2'>
                    <div className='d-flex flex-column'>
                        <span className="fw-bold" style={{ color: '#005198' }}>
                            <small>{transaction.sender}</small>
                        </span>
                        <span className="text-secondary fw-bold fst-italic">
                            <small>{transaction.transactionNumber}</small>
                            {transaction.note && (
                                <small> | {transaction.note}</small>
                            )}
                        </span>
                    </div>
                </div>

                <div className='col-md-4 justify-content-end d-flex align-items-center text-end'>
                    <span className="text-end m-2 fw-bold text-wrap" style={{ color: '#005198' }}><FormattedNumber value={transaction.amount} style="currency" currency="USD" /></span>
                    {accountInfo.session.attributes.sub === transaction.createdBy && (<IconButton className='text-info' title='Edit' aria-label='Edit'>
                        <EditIcon onClick={() => setShowEdit(true)} />
                    </IconButton>
                    )}
                    {accountInfo.session.attributes.sub === transaction.createdBy && (
                        < IconButton className='text-danger' title='Delete' aria-label='Delete'>
                            <DeleteIcon onClick={() => setShowDelete(true)} />
                        </IconButton>
                    )}
                    {isAdmin && (
                        <IconButton className='text-success' title='Confirm' aria-label='Confirm'>
                            <ThumbUpAltIcon onClick={() => { setShowConfirm(true) }} />
                        </IconButton>
                    )}
                </div>
            </div>

            {showConfirm &&
                <ConfirmPaymentModal
                    closeModal={handleCloseModals}
                    closeModalRefresh={handleCloseRefresh}
                    isOpen={showConfirm}
                    payment={transaction}
                />
            }

            {(showEdit) &&
                <CreateEditZellePaymentModal
                    closeModal={handleCloseModals}
                    closeModalRefresh={handleCloseRefresh}
                    isOpen={showEdit}
                    edit={showEdit}
                    item={transaction}
                />
            }

            {(showDelete) &&
                <DeletePayment
                    closeModal={handleCloseModals}
                    closeModalRefresh={handleCloseRefresh}
                    isOpen={showDelete}
                    payment={transaction}
                />
            }
        </>
    );
}

export default PendingTransactionItemCard;