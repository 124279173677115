import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useState, useEffect } from "react";
import { FormattedMessage } from 'react-intl';
import { useSelector, useDispatch } from "react-redux";
import { Formik } from 'formik';
import * as yup from "yup";
import { requestedCreateBooking, requestedUpdateBooking, selectBookingsStatus } from '../_redux/bookingsSlice';
import { selectIsAdmin } from '../../Account/_redux/accountSlice';
import { v4 as uuidv4 } from 'uuid';


function CreateEditBookingModal(props) {
    const { isOpen, closeModal, closeModalRefresh, edit, item, route } = props;
    const dispatch = useDispatch();

    const bookingStatus = useSelector((state) => selectBookingsStatus(state));
    const isAdmin = useSelector((state) => selectIsAdmin(state));

    useEffect(() => {
        if (bookingStatus === 'submitted') {
            closeModalRefresh();
        }
    }, [bookingStatus]);

    const schema = yup.object().shape({
        name: yup.string().required(),
        bookingNumber: yup.string(),
        bookingNumberReturn: yup.string(),
        dateBirth: yup.string().required(),
        passport: yup.string().required(),
        passportExpiration: yup.string().required(),
        gender: yup.string().required(),
        class: yup.string().required(),
        note: yup.string(),
        email: yup.string().email(),
        status: yup.string()
    });

    const handleSubmitForm = (values, actions) => {
        if (edit) {
            dispatch(requestedUpdateBooking(values));
        } else {
            let id = 'book_' + uuidv4();
            dispatch(requestedCreateBooking({ ...values, bookingId: id, routeId: route.routeId }));
        }
        actions.setSubmitting(false);
    };

    return (
        <Formik
            validationSchema={schema}
            onSubmit={handleSubmitForm}
            initialValues={edit ? item : {
                bookingNumber: '',
                bookingNumberReturn: '',
                name: '',
                dateBirth: '',
                passport: '',
                passportExpiration: '',
                gender: '',
                class: 'Economy',
                note: '',
                email: '',
                status: 'Unconfirmed'
            }}
        >
            {({
                handleSubmit,
                handleChange,
                handleBlur,
                isSubmitting,
                values,
                touched,
                isValid,
                errors,
            }) => (
                <Modal show={isOpen} onHide={closeModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <FormattedMessage id={edit ? "Travel.edit_booking" : "Travel.new_booking"} />
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form noValidate onSubmit={handleSubmit}>
                            {isAdmin && (item?.status === 'Processed' || item?.status === 'Confirmed') && (
                                <>
                                    <Row className="mb-3">
                                        <Form.Group as={Col} md="6">
                                            <Form.Label>
                                                <FormattedMessage id="Labels.status" />
                                            </Form.Label>
                                            <Form.Select
                                                name='status'
                                                onChange={handleChange}
                                                value={values.status}
                                                isValid={touched.status && !errors.status}
                                                isInvalid={!!errors.status}
                                            >
                                                <option value="Processed">Processed</option>
                                                <option value="Confirmed">Confirmed</option>
                                            </Form.Select>
                                            <Form.Control.Feedback />
                                            <Form.Control.Feedback type="invalid" />
                                        </Form.Group>
                                    </Row>
                                    <Row className="mb-3">
                                        <Form.Group as={Col} md="6">
                                            <Form.Label>
                                                <FormattedMessage id="Labels.booking" /> #
                                            </Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="bookingNumber"
                                                onChange={handleChange}
                                                value={values.bookingNumber}
                                                isValid={touched.bookingNumber && !errors.bookingNumber}
                                                isInvalid={!!errors.bookingNumber}
                                            />
                                            <Form.Control.Feedback />
                                            <Form.Control.Feedback type="invalid" />
                                        </Form.Group>
                                        <Form.Group as={Col} md="6">
                                            <Form.Label>
                                                <FormattedMessage id="Labels.booking_return" /> #
                                            </Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="bookingNumberReturn"
                                                onChange={handleChange}
                                                value={values.bookingNumberReturn}
                                                isValid={touched.bookingNumberReturn && !errors.bookingNumberReturn}
                                                isInvalid={!!errors.bookingNumberReturn}
                                            />
                                            <Form.Control.Feedback />
                                            <Form.Control.Feedback type="invalid" />
                                        </Form.Group>
                                    </Row>
                                </>
                            )}
                            <Row className="mb-3">
                                <Form.Group as={Col} md="6">
                                    <Form.Label>
                                        <FormattedMessage id="Labels.name" />
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="name"
                                        onChange={handleChange}
                                        value={values.name}
                                        isValid={touched.name && !errors.name}
                                        isInvalid={!!errors.name}
                                    />
                                    <Form.Control.Feedback />
                                    <Form.Control.Feedback type="invalid" />
                                </Form.Group>
                                <Form.Group as={Col} md="6">
                                    <Form.Label>
                                        <FormattedMessage id="Labels.date_birth" />
                                    </Form.Label>
                                    <Form.Control
                                        type="date"
                                        name="dateBirth"
                                        onChange={handleChange}
                                        value={values.dateBirth}
                                        isValid={touched.dateBirth && !errors.dateBirth}
                                        isInvalid={!!errors.dateBirth}
                                    />
                                    <Form.Control.Feedback />
                                    <Form.Control.Feedback type="invalid" />
                                </Form.Group>
                            </Row>
                            <Row className="mb-3">
                                <Form.Group as={Col} md="6">
                                    <Form.Label>
                                        <FormattedMessage id="Labels.passport" />
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="passport"
                                        onChange={handleChange}
                                        value={values.passport}
                                        isValid={touched.passport && !errors.passport}
                                        isInvalid={!!errors.passport}
                                    />
                                    <Form.Control.Feedback />
                                    <Form.Control.Feedback type="invalid" />
                                </Form.Group>
                                <Form.Group as={Col} md="6">
                                    <Form.Label>
                                        <FormattedMessage id="Labels.passport_expiration" />
                                    </Form.Label>
                                    <Form.Control
                                        type="date"
                                        name="passportExpiration"
                                        onChange={handleChange}
                                        value={values.passportExpiration}
                                        isValid={touched.passportExpiration && !errors.passportExpiration}
                                        isInvalid={!!errors.passportExpiration}
                                    />
                                    <Form.Control.Feedback />
                                    <Form.Control.Feedback type="invalid" />
                                </Form.Group>
                            </Row>
                            <Row className="mb-3">
                                <Form.Group as={Col} md="6">
                                    <Form.Label>
                                        <FormattedMessage id="Labels.gender" />
                                    </Form.Label>
                                    <Form.Select
                                        name='gender'
                                        onChange={handleChange}
                                        value={values.gender}
                                        isValid={touched.gender && !errors.gender}
                                        isInvalid={!!errors.gender}
                                    >
                                        <option value="">Select</option>
                                        <option value="Male"><FormattedMessage id="Label.male" /></option>
                                        <option value="Female"><FormattedMessage id="Label.female" /></option>
                                    </Form.Select>
                                    <Form.Control.Feedback />
                                    <Form.Control.Feedback type="invalid" />
                                </Form.Group>
                                <Form.Group as={Col} md="6">
                                    <Form.Label>
                                        <FormattedMessage id="Labels.class" />
                                    </Form.Label>
                                    <Form.Select
                                        name='class'
                                        onChange={handleChange}
                                        value={values.class}
                                        isValid={touched.class && !errors.class}
                                        isInvalid={!!errors.class}
                                    >
                                        <option value="Economy"><FormattedMessage id="Travel.economyClass" /></option>
                                        <option value="Premium_Economy"><FormattedMessage id="Travel.premiumClass" /></option>
                                        <option value="Business"><FormattedMessage id="Travel.businessClass" /></option>
                                        <option value="First_Class"><FormattedMessage id="Travel.firstClass" /></option>
                                    </Form.Select>
                                    <Form.Control.Feedback />
                                    <Form.Control.Feedback type="invalid" />
                                </Form.Group>
                            </Row>
                            <Row className="mb-3">
                                <Form.Group as={Col} md="6">
                                    <Form.Label>
                                        <FormattedMessage id="Labels.email" />
                                    </Form.Label>
                                    <Form.Control
                                        type="email"
                                        name="email"
                                        onChange={handleChange}
                                        value={values.email}
                                        isValid={touched.email && !errors.email}
                                        isInvalid={!!errors.email}
                                    />
                                    <Form.Control.Feedback />
                                    <Form.Control.Feedback type="invalid" />
                                </Form.Group>
                                <Form.Group as={Col} md="6">
                                    <Form.Label>
                                        <FormattedMessage id="Labels.note" />
                                    </Form.Label>
                                    <textarea
                                        className="form-control"
                                        name="note"
                                        rows={3}
                                        onChange={handleChange}
                                        value={values.note}
                                        isValid={touched.note && !errors.note}
                                        isInvalid={!!errors.note}
                                    />
                                    <Form.Control.Feedback />
                                    <Form.Control.Feedback type="invalid" />
                                </Form.Group>
                            </Row>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={closeModal}>
                            <FormattedMessage id="Actions.close" />
                        </Button>
                        <Button variant="primary" disabled={!isValid || isSubmitting} onClick={handleSubmit}>
                            {(isSubmitting || bookingStatus === 'loading') && (
                                <Spinner
                                    as="span"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    animation="border"
                                />
                            )}
                            <FormattedMessage id="Actions.save" />
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}
        </Formik>
    );
}

export default CreateEditBookingModal;