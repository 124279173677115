import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Skeleton from '@mui/material/Skeleton';
import { useState, useEffect } from "react";
import { useIntl, FormattedMessage } from 'react-intl';
import { useSelector, useDispatch } from "react-redux";
import { requestedRoutesList, selectAllRoutes, selectRoutesStatus } from '../_redux/routesSlice';
import CreateEditBookingModal from '../_subs/CreateEditBookingModal';
import DeleteRouteModal from '../_subs/DeleteRouteModal';
import RouteItemCard from '../_subs/RouteItemCard';
import { useNavigate } from 'react-router-dom';

function Book(props) {
  const intl = useIntl();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const allRoutes = useSelector((state) => selectAllRoutes(state));
  const routeStatus = useSelector((state) => selectRoutesStatus(state));
  const [loading, setLoading] = useState(true);
  const [selectedItem, setSelectedItem] = useState();
  const [showAddBooking, setShowAddBooking] = useState(false);

  useEffect(() => {
    refreshTable();
  }, [props]);

  const refreshTable = () => {
    setSelectedItem({});
    dispatch(requestedRoutesList());
    setLoading(false);
  }

  const handleCloseModals = () => {
    setShowAddBooking(false);
  };

  const handleCloseNewRefresh = () => {
    handleCloseModals();
    navigate('/travel/bookings');
  };

  const handleShowAddBooking = (route) => {
    setSelectedItem(route);
    setShowAddBooking(true);
  }

  return (
    <>
      <Container>
        <div style={{ marginBottom: 5, width: '100%' }} className="d-flex justify-content-end">
          <Button variant="primary" onClick={() => navigate('/travel/bookings')}>
            <FormattedMessage
              id="Labels.bookings"
            />
          </Button>
        </div>
        <div className='mb-5'>
          {(loading || routeStatus === 'loading') ? (
            <>
              <Skeleton height={60} />
              <Skeleton animation="wave" height={60} />
              <Skeleton animation={false} height={60} />
            </>
          ) : (
            allRoutes.map((row) => (
              <RouteItemCard key={row.routeId} route={row} booking={true} addBooking={handleShowAddBooking} />
            ))
          )}
        </div >
      </Container>

      {showAddBooking &&
        <CreateEditBookingModal
          closeModal={handleCloseModals}
          closeModalRefresh={handleCloseNewRefresh}
          isOpen={showAddBooking}
          edit={false}
          route={selectedItem}
        />
      }

    </>
  )
}

export default Book;