import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import Skeleton from '@mui/material/Skeleton';
import { useState, useEffect } from "react";
import { useIntl, FormattedMessage, FormattedDate, FormattedTime, FormattedNumber } from 'react-intl';
import { useSelector, useDispatch } from "react-redux";
import AWS from 'aws-sdk';
import CreateUserModal from '../_subs/CreateUserModal';
import DeleteUserModal from '../_subs/DeleteUserModal';
import CreateEditZellePaymentModal from '../_subs/CreateEditZellePaymentModal';
import DeleteIcon from '@mui/icons-material/Delete';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
const userPoolId = 'us-east-1_bwjXklaEw';

AWS.config.region = 'us-east-1';
const cognito = new AWS.CognitoIdentityServiceProvider();

function Users(props) {
  const intl = useIntl()
  const dispatch = useDispatch();

  const [usersList, setUsersList] = useState([]);

  const [loading, setLoading] = useState(true);
  const [selectedUser, setSelectedUser] = useState([]);
  const [showNew, setShowNew] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [showPayment, setShowPayment] = useState(false);

  useEffect(() => {
    refreshTable();
  }, [props]);

  const refreshTable = () => {
    setLoading(true);
    var params = {
      UserPoolId: userPoolId, /* required */
      AttributesToGet: [
        'email',
        'name',
        'custom:credit'
      ],
      //Filter: 'STRING_VALUE',
      //Limit: '100',
      //PaginationToken: 'STRING_VALUE'
    };
    cognito.listUsers(params, function (err, data) {
      if (err) {
        console.log(err, err.stack); // an error occurred
      } else {
        setUsersList(data.Users.map((user) => ({ ...user, id: user.Username, name: user.Attributes.filter((item) => (item['Name'] === "name"))[0].Value, email: user.Attributes.filter((item) => (item['Name'] === "email"))[0].Value, credit: user.Attributes.filter((item) => (item['Name'] === "custom:credit"))[0].Value })));
        console.log(data);           // successful response
        setLoading(false);
      }
    });
  }

  const handleShowNew = () => setShowNew(true);
  const handleCloseModals = () => {
    setShowNew(false);
    setSelectedUser({});
    setShowDelete(false);
    setShowPayment(false);
  };
  const handleCloseRefresh = () => {
    handleCloseModals();
    refreshTable();
  };

  const handleShowDelete = (user) => {
    let name = user.Attributes.filter((item) => (item['Name'] === "name"));
    if (name.length > 0) {
      name = name[0].Value;
    }
    let email = user.Attributes.filter((item) => (item['Name'] === "email"));
    if (email.length > 0) {
      email = email[0].Value;
    }
    setSelectedUser({
      name,
      email
    });
    setShowDelete(true);
  }

  const handleShowPayment = (user) => {
    setSelectedUser(user);
    setShowPayment(true);
  }

  const columns = [
    {
      field: 'UserCreateDate', headerName: intl.formatMessage({ id: 'Labels.created' }), width: 100, renderCell: (params) => (
        <>
          <div className='d-flex flex-column'>
            <span className="text-secondary fw-bold fst-italic">
              <small><FormattedDate month='numeric' day='numeric' year='numeric' value={new Date(params.value)} /></small>
            </span>
            <span className="text-secondary fw-bold fst-italic">
              <small><FormattedTime hour12={true} value={new Date(params.value)} /></small>
            </span>
          </div>
        </>
      )
    },
    {
      field: 'UserLastModifiedDate', headerName: intl.formatMessage({ id: 'Labels.updated' }), width: 100, renderCell: (params) => (
        <>
          <div className='d-flex flex-column'>
            <span className="text-secondary fw-bold fst-italic">
              <small><FormattedDate month='numeric' day='numeric' year='numeric' value={new Date(params.value)} /></small>
            </span>
            <span className="text-secondary fw-bold fst-italic">
              <small><FormattedTime hour12={true} value={new Date(params.value)} /></small>
            </span>
          </div>
        </>
      )
    },
    {
      field: 'name', headerName: intl.formatMessage({ id: 'Labels.name' }), minWidth: 250, flex: 1
    },
    {
      field: 'email', headerName: intl.formatMessage({ id: 'Labels.email' }), minWidth: 250, flex: 1
    },
    {
      field: 'credit', align: 'right', headerName: intl.formatMessage({ id: 'Labels.credit' }), minWidth: 250, flex: 1, renderCell: (params) => {
        return <span className='fw-bold'><FormattedNumber value={params.value} style="currency" currency="USD" /></span>
      }
    },
    { field: 'UserStatus', headerName: intl.formatMessage({ id: 'Labels.status' }), width: 180, renderCell: (params) => (<span className={params.value === 'FORCE_CHANGE_PASSWORD' ? 'badge bg-warning text-dark' : (params.value === 'CONFIRMED' ? 'badge bg-info text-dark' : 'badge bg-danger')}>{params.value}</span>) },
    {
      field: 'actions',
      type: 'actions',
      width: 120,
      getActions: (params) => [
        <GridActionsCellItem style={{ color: 'green' }} icon={<MonetizationOnIcon onClick={() => {
          handleShowPayment(params.row);
        }
        } />} label="Transaction" />
        ,
        <GridActionsCellItem style={{ color: 'red' }} icon={<DeleteIcon onClick={() => {
          handleShowDelete(params.row);
        }
        } />} label="Delete" />
        ,
      ],
    }
  ];

  return (
    <>
      <Container>
        <div style={{ marginBottom: 5, width: '100%' }} className="d-flex justify-content-end">
          <Button variant="primary" onClick={handleShowNew}>
            <FormattedMessage
              id="Actions.new"
            />
          </Button>
        </div>
        <div style={{ height: 700, width: '100%' }}>
          {(loading === 'loading') ? (
            <>
              <Skeleton height={60} />
              <Skeleton animation="wave" height={60} />
              <Skeleton animation={false} height={60} />
            </>
          ) : (
            <DataGrid
              rows={usersList}
              columns={columns}
              //pageSize={50}
              rowsPerPageOptions={[5, 10, 50, 100, 500, 1000]}
            />
          )}
        </div >
      </Container>

      {(showNew) &&
        <CreateUserModal
          closeModal={handleCloseModals}
          closeModalRefresh={handleCloseRefresh}
          isOpen={showNew}
        />
      }

      {(showDelete) &&
        <DeleteUserModal
          closeModal={handleCloseModals}
          closeModalRefresh={handleCloseRefresh}
          isOpen={showDelete}
          item={selectedUser}
        />
      }

      {(showPayment) &&
        <CreateEditZellePaymentModal
          closeModal={handleCloseModals}
          closeModalRefresh={handleCloseRefresh}
          isOpen={showPayment}
          edit={false}
          item={undefined}
          user={selectedUser}
          isAdmin={true}
        />
      }

    </>
  )
}

export default Users;