import {
    createSlice,
    createAsyncThunk,
    createEntityAdapter
} from '@reduxjs/toolkit';
import axios from "axios";

const AIRLINES_URL = "travel/airlines";

const airlinesAdapter = createEntityAdapter();
const initialState = airlinesAdapter.getInitialState({
    status: 'idle',
    current: undefined,
    errors: undefined
});


// Thunk functions
export const requestedAirlinesList = createAsyncThunk('airlines/requestedAirlinesList', async (noparams, { getState, rejectWithValue }) => {
    const response = await axios.get(AIRLINES_URL, {
        headers: {
            'authorization-token': getState().account.current.session.signInUserSession.idToken.jwtToken
        }
    });

    if (response.data.success) {
        return response.data.content.map((item) => {return {...item, id:item.airlineId};});
    } else {
        throw rejectWithValue(response.data);
    }
});

export const requestedAirlineDetailsById = createAsyncThunk('airlines/requestedAirlineDetailsById', async (airlineId, { getState, rejectWithValue }) => {
    const response = await axios.get(`${AIRLINES_URL}/${airlineId}`, {
        headers: {
            'authorization-token': getState().account.current.session.signInUserSession.idToken.jwtToken
        }
    });

    if (response.data.success) {
        return response.data.content;
    } else {
        throw rejectWithValue(response.data);
    }
});

export const requestedCreateAirline = createAsyncThunk('airlines/requestedCreateAirline', async (airline, { getState, rejectWithValue }) => {
    const response = await axios.post(`${AIRLINES_URL}/`, airline, {
        headers: {
            'authorization-token': getState().account.current.session.signInUserSession.idToken.jwtToken
        }
    });

    if (response.data.success) {
        return response.data.content;
    } else {
        throw rejectWithValue(response.data);
    }
});

export const requestedUpdateAirline = createAsyncThunk('airlines/requestedUpdateAirline', async (airline, { getState, rejectWithValue }) => {
    delete airline.id;
    const response = await axios.put(`${AIRLINES_URL}/${airline.airlineId}`, airline, {
        headers: {
            'authorization-token': getState().account.current.session.signInUserSession.idToken.jwtToken
        }
    });

    if (response.data.success) {
        return response.data.content;
    } else {
        throw rejectWithValue(response.data);
    }
});

export const requestedDeleteAirlines = createAsyncThunk('airlines/requestedDeleteAirlines', async (ids, { getState, rejectWithValue }) => {
    const response = await axios.delete(`${AIRLINES_URL}/`, {
        data: {ids},
        headers: {
            'authorization-token': getState().account.current.session.signInUserSession.idToken.jwtToken
        }
    });

    if (response.data.success) {
        return response.data.content;
    } else {
        throw rejectWithValue(response.data);
    }
});

const airlinesSlice = createSlice({
    name: 'airlines',
    initialState,
    reducers: {
    },
    extraReducers: builder => {
        builder
            .addCase(requestedAirlinesList.pending, (state, action) => {
                state.status = 'loading';
                state.ids = [];
                state.entities = {};
                state.current = undefined;
                state.errors = undefined;
            })
            .addCase(requestedAirlinesList.fulfilled, (state, action) => {
                state.status = 'loaded';
                airlinesAdapter.addMany(state, action);
            })
            .addCase(requestedAirlinesList.rejected, (state, action) => {
                state.status = 'error';
                state.errors = action.payload?.errors;
            })

            .addCase(requestedAirlineDetailsById.pending, (state, action) => {
                state.status = 'loading';
                state.current = undefined;
                state.errors = undefined;
            })
            .addCase(requestedAirlineDetailsById.fulfilled, (state, action) => {
                state.status = 'loaded';
                state.current = action.payload;
            })
            .addCase(requestedAirlineDetailsById.rejected, (state, action) => {
                state.status = 'error';
                state.errors = action.payload?.errors;
            })

            .addCase(requestedCreateAirline.pending, (state, action) => {
                state.status = 'loading';
                state.errors = undefined;
            })
            .addCase(requestedCreateAirline.fulfilled, (state, action) => {
                state.status = 'submitted';
                state.current = action.payload;
            })
            .addCase(requestedCreateAirline.rejected, (state, action) => {
                state.status = 'error';
                state.errors = action.payload?.errors;
            })

            .addCase(requestedUpdateAirline.pending, (state, action) => {
                state.status = 'loading';
                state.errors = undefined;
            })
            .addCase(requestedUpdateAirline.fulfilled, (state, action) => {
                state.status = 'submitted';
                state.current = action.payload;
            })
            .addCase(requestedUpdateAirline.rejected, (state, action) => {
                state.status = 'error';
                state.errors = action.payload?.errors;
            })

            .addCase(requestedDeleteAirlines.pending, (state, action) => {
                state.status = 'loading';
                state.errors = undefined;
            })
            .addCase(requestedDeleteAirlines.fulfilled, (state, action) => {
                state.status = 'deleted';
                state.current = action.payload;
            })
            .addCase(requestedDeleteAirlines.rejected, (state, action) => {
                state.status = 'error';
                state.errors = action.payload?.errors;
            })
    }
});

// Selectors
export const { selectAll: selectAllAirlines, selectById: selectAirlineById, selectIds: selectAirlinesIds, selectTotal: selectTotalAirlines } =
airlinesAdapter.getSelectors(state => state.airlines);

export const selectAirlinesStatus = (state) => state.airlines.status;
export const selectAirlinesCurrent = (state) => state.airlines.current;
export const selectAirlinesErrors = (state) => state.airlines.errors;

export default airlinesSlice.reducer