import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { useSelector, useDispatch } from "react-redux";
import { FormattedMessage } from 'react-intl';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { selectAccountInfo, requestedUpdateLan, selectIsAdmin } from '../components/Account/_redux/accountSlice';
import { Auth } from 'aws-amplify';
import { useEffect, useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';    

function Header(props) {
    const dispatch = useDispatch();

    const accountInfo = useSelector((state) => selectAccountInfo(state));
    const isAdmin = useSelector((state) => selectIsAdmin(state));

    const handleChangeLanguage = function (event) {
        const lan = event.target.value;
        dispatch(requestedUpdateLan(lan));
    }

    return (
        <>
            <Navbar bg="light" expand="lg" className="mb-3">
                <Container>
                    <Navbar.Brand href="/">
                        <img
                            alt=""
                            src="/logo-circle.png"
                            height="80"
                        />{' '}
                        {/*<span>FR Solutions Travel</span>*/}
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-lg`} />
                    <Navbar.Offcanvas
                        id={`offcanvasNavbar-expand-lg`}
                        aria-labelledby={`offcanvasNavbarLabel-expand-lg`}
                        placement="end"
                    >
                        <Offcanvas.Header closeButton>
                            <Offcanvas.Title id={`offcanvasNavbarLabel-expand-lg`}>
                                <img
                                    alt=""
                                    src="/logo-circle.png"
                                    height="80"
                                />{' '}
                                {/*<span>FR Solutions Travel</span>*/}
                            </Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                            <Nav className="justify-content-start flex-grow-1 pe-3">
                                <Nav.Link href="/travel/bookings"><FormattedMessage id="Labels.bookings" /></Nav.Link>
                                <Nav.Link href="/travel/book"><FormattedMessage id="Labels.book" /></Nav.Link>
                                {isAdmin && <Nav.Link href="/travel/routes"><FormattedMessage id="Labels.routes" /></Nav.Link>}
                                {isAdmin && <Nav.Link href="/travel/airlines"><FormattedMessage id="Labels.airlines" /></Nav.Link>}
                                {isAdmin && <Nav.Link href="/travel/airports"><FormattedMessage id="Labels.airports" /></Nav.Link>}
                                {isAdmin && <Nav.Link href="/users"><FormattedMessage id="Labels.users" /></Nav.Link>}
                                {isAdmin && <Nav.Link href="/transactions"><FormattedMessage id="Labels.transactions" /></Nav.Link>}
                            </Nav>
                            <Form className="d-flex">
                                <Form.Select size="lg" className="me-2" value={accountInfo.lan} onChange={handleChangeLanguage}>
                                    <option value={"en"}>EN</option>
                                    <option value={"es"}>ES</option>
                                </Form.Select>
                                <Dropdown>
                                    <Dropdown.Toggle style={{ padding: '0.75rem', paddingX: '0.375rem' }} size='large' variant="outline-info">
                                        {accountInfo.session?.attributes?.name}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu alignRight={true}>
                                        <Dropdown.Item onClick={() => window.location = '/profile'}>
                                            <FormattedMessage id="Labels.profile" />
                                        </Dropdown.Item>
                                        <Dropdown.Item onClick={() => Auth.signOut()}>
                                            Logout
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Form>
                        </Offcanvas.Body>
                    </Navbar.Offcanvas>
                </Container>
            </Navbar>
            {0 == 1 && (<Navbar sticky="top" bg="light" variant="light">
                <Container>
                    <Navbar.Brand href="/">
                        <img
                            alt=""
                            src="/logo-circle.png"
                            height="80"
                        />{' '}
                        <span>Envios a Cuba</span>
                    </Navbar.Brand>
                </Container>
            </Navbar>)}
        </>
    )
}

export default Header;